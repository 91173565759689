import React from 'react';
import { Link } from "react-router-dom";

function HireFooter() {
  const isLinkDisabled = true;

  const handleClick = (event) => {
    if (isLinkDisabled) {
      event.preventDefault();
    }
  };
  return (
    <>
      <footer className='footer'>
        <div className='footer-content'>
          <div className="container">
          <div className='row'>
            <div className='col-lg-6'>
              <div className='logo'>Alyya</div>
              <div className='content'>
              “IT” (otherwise known as HD 36405.b) isn't your average “oddball” exo-solar planet made of rock that wobbles on its end & zips around a nearby star in less than 2.46 days.
              </div>
            </div>
            <div className='col-lg-6 pt-3'>
              <div className='row'>
                <div className='col-sm-4'>
                  <h5 className='title'>Company</h5>
                  <Link className="quick-links" to="/home/about-us">About Us</Link>
                  {/* <Link className="quick-links" to="/home/blogs">Blog</Link> */}
                  <Link className="quick-links" to="/home/contact-us">Contact Us</Link>
                </div>
                <div className='col-sm-4'>
                  <h5 className='title'>Legal</h5>
                  <Link className="quick-links" to="/home/terms-and-conditions">Terms & Conditions</Link>
                  {/* <Link className="quick-links" to="/home/privacy-policy">Privacy Policy</Link>
                  <Link className="quick-links" to="/home/legal-information">Legal Information</Link> */}
                </div>
                <div className='col-sm-4'>
                  <h5 className='title'>Explore</h5>
                  {/* <Link className="quick-links" to="/home/how-it-works">How it works?</Link>
                  <Link className={`quick-links ${isLinkDisabled ? 'disabled' : ''}`} to="/home/how-it-works" onClick={handleClick} style={{ pointerEvents: isLinkDisabled ? 'none' : 'auto' }}>
                    How it works?
                  </Link>
                  <Link className="quick-links" to="/home/help">Help</Link> */}
                  <Link className="quick-links" to="/home/faqs">FAQs</Link>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
        <div className='copyright'>
          <div className="container">
            {/* <div className="social-icons">
              <a href="https://www.facebook.com/" target="_blank"><img className="icons" src="/images/facebook.png" alt="facebook" /></a>
              <a href="https://twitter.com/" target="_blank"><img className="icons" src="/images/twitter.png" alt="twitter" /></a>
              <a href="https://www.instagram.com/" target="_blank"><img className="icons" src="/images/instagram.png" alt="instagram" /></a>
            </div> */}
            <span className='copy-content'>© Alyya {new Date().getFullYear()} All Rights Reserved.</span>
          </div>          
        </div>
      </footer>
    </>
  )
};

export default HireFooter;