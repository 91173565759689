import React, { useRef } from 'react';

const ChatSection = React.memo(({ selectedChat, chatHistory, chatContainerRef, userMessage, userInputHandler, handleKeyDown, sendMessageHandler, handeleCloseChat ,onlineStatus}) => {
  const myUniqueAuthId = localStorage.getItem("unique_auth_id")
  const user = selectedChat?.accounts_chats?.find(accountChat => accountChat.attributes.account_info.unique_auth_id != myUniqueAuthId)

  const options = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  };

  return (
    <div>
      <div className="dashboardChatHeaderPrivate underscore">
        <div onClick={() => handeleCloseChat()}>
          <img src="/images/backArrow.jpg" alt="Back" />
        </div>
        <div className={`${onlineStatus === true ? "dashboardChatAvatar" : " chatSectionImg"}`}>
          {user?.attributes?.account_info?.image ? (
            <img className='chatSectionImg'  src={user.attributes.account_info.image} alt="user avatar" />
          ) : (
            <div
              style={{
                width: 40,
                height: 40,
                borderRadius: '50%',
                backgroundColor: "#ff8d27",
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'white',
                fontSize: 24,
              }}
            >
              {user?.attributes?.account_info?.full_name ? user?.attributes?.account_info?.full_name.charAt(0).toUpperCase() : "?"}
            </div>
          )}
        </div>
        <p>{user?.attributes?.account_info?.full_name}</p>
      </div>
      <div className="chatSection">
        <div className="messagesDashboardContainer" ref={chatContainerRef}>
          {chatHistory ? 
            chatHistory?.map((chatGroup, index) => (
              <div className="dashboardChatHistory" data-testid={`chat-message`} key={index}>
                <div className="divider">
                  <span>{chatGroup.datetime}</span>
                </div>
                {chatGroup?.messages && chatGroup?.messages.map((msg) => {
                  const date = new Date(msg?.attributes?.raw_created_at);
                  return (
                    <div
                      key={msg.id}
                      className={`messageDashboard ${msg?.attributes?.is_sender ? 'sent' : 'received'}`}
                      data-testid={`message-${msg.id}`}
                    >
                      <p>{msg.attributes.message}</p>
                      <span className="timestamp">{date.toLocaleString('en-US', options).toLocaleLowerCase()}</span>
                    </div>
                  );
                })}
              </div>
            ))
            :
            <div data-testid="no-message">No messages yet</div>
          }
        </div>
      </div>
      <div className="inputSectionDashboard">
        <input
          placeholder="Type your message..."
          value={userMessage}
          onChange={userInputHandler}
          onKeyDown={handleKeyDown}
        />
        <button
          className="chatButtonDashboard"
          onClick={() => sendMessageHandler(selectedChat?.id, userMessage)}
        >
          <p>Send</p>
        </button>
      </div>
    </div>
  );
});

export default ChatSection;
