import React, {useEffect, useRef, useState} from 'react';
import Header from '../Header';
import Sidebar from '../Sidebar';
import axios from 'axios';
import appConstant from '../../shared/constant/constant.json';
import Moment from 'moment';
import LoadingSpinner from '../../shared/Loader';
import { Chart } from "react-google-charts";
import ChatSectionItem from './ChatSectionItem';
import { Link } from 'react-router-dom';
import { fetchChatList, fetchPrivateChat, markMessagesAsRead, msgHistory, sendMessage } from '../../shared/services/chatApi';
import websocketService from '../../shared/services/webSocketService';
import ChatSection from './ChatSection';
import { parseAllChats } from '../../shared/utils/chatUtils';

function Dashboard() {
  const [dashboardData, setDashboardData] = React.useState({});
  let [notifications, setNotifications] = useState([]);
  let [isNotificationLoading, setIsNotificationLoading] = useState(false);
  let [graphData, setGraphData] = useState([  ['Duration', 'Amount'],['01-01-2023', 0],['01-02-2023', 0], ['01-03-2023', 0], ['01-04-2023', 0]
  , ['01-05-2023', 0], ['01-06-2023', 0], ['01-07-2023', '42,777.00'], ['01-08-2023', '106,567.00']
  , ['01-09-2023', '141,315.00']]);
  let [isGraphLoading, setIsGraphLoading] = useState(true);
  const [inputValues, setInputValue] = useState({
    type: "this_month",
  });

  const [selectedChat, setSelectedChat] = useState(null);
  const [chatHistory, setChatHistory] = useState({
    datatime:"",
    messages: [],
    meta: {} 
  });
  const [userMessage, setUserMessage] = useState("");
  const [chatsList, setChatsList] = useState([]);
  const [chatId, setchatID] = useState();
  const [unreadCount, setUnreadCount] = useState(0);
  const [currentMsgHistoryPage, setCurrentMsgHistoryPage] = useState(1); 
  const [totalMsgHistoryPage, setTotalMsgHistoryPage] = useState(null);  
  const chatContainerRef = useRef(null);
  const [isLoading, setIsLoading] = useState();
  const [usersOnlineStatus, setUsersOnlineStatus] = useState({});
  const [isSending, setIsSending] = useState(false)

  useEffect(() => {
    dashboardPage();
    getNotifications();
    getGraph();
  }, []);

  useEffect(() => {
    loadChatList();
    websocketService.connect(handleMessageReceived, updateOnlineStatus);

    return () => {
        websocketService.disconnect(); 
    };
  }, [selectedChat]);

  useEffect(() => {
    const chatContainer = chatContainerRef.current;
  
    if (chatContainer) {
      chatContainer.addEventListener('scroll', handleScroll);
      
      return () => {
        chatContainer.removeEventListener('scroll', handleScroll);
      };
    }
  }, [currentMsgHistoryPage, totalMsgHistoryPage, selectedChat]);

  

  const updateOnlineStatus = (userName, isOnline) => {
    setUsersOnlineStatus((prevState) => ({
      ...prevState,
      [userName]: isOnline,
    }));
  };

  const loadChatList = async () => {
    try {
        const res = await fetchChatList();
        const parsedChats = parseAllChats(res);
        setChatsList(res.slice(0,4)); 
        const totalUnreadCount = parsedChats.reduce((total, chat) => {
          return total + (chat.unreadCount || 0);
      }, 0);;
        setUnreadCount(totalUnreadCount)
    } catch (error) {
        console.error('Error fetching chats:', error);
    }
  };

  const loadMoreMessages = async (chatId, page) => {
    try {
      const res = await msgHistory(chatId, page);
      if (res.data && res.data.data && res.data.data.length > 0) {
        const newMessages = res.data.data || [];
        const meta = res.data.meta;
        const reversedChatHistory = newMessages.map(chatGroup => ({
          ...chatGroup,
          messages: chatGroup.messages.reverse() 
        }));
        setChatHistory((prevState) => {
          const updatedChatHistory = [...prevState];
          reversedChatHistory.forEach((newGroup) => {
            const existingGroupIndex = updatedChatHistory.findIndex(
              (prevGroup) => prevGroup.datetime === newGroup.datetime
            );
            if (existingGroupIndex !== -1) {
              updatedChatHistory[existingGroupIndex].messages = [
                ...newGroup.messages,
                ...updatedChatHistory[existingGroupIndex].messages
              ];
            } else {
              updatedChatHistory.unshift(newGroup); 
            }
          });
          return updatedChatHistory;
        });
  
        setCurrentMsgHistoryPage(meta.current_page);
        setTotalMsgHistoryPage(meta.total_pages);
      } else {
        console.warn("No more messages to load or invalid response format.");
      }
    } catch (error) {
      console.error('Error loading more messages:', error);
    }
  };
  
  

  const scrollToBottom = () => {
    const chatContainer = chatContainerRef.current;
    if (chatContainer) {
      chatContainer.scrollTo({
        top: chatContainer.scrollHeight, 
        behavior: 'smooth' 
      });
    }
  };

  const handleScroll = () => {
    const chatContainer = chatContainerRef.current;
    if (chatContainer.scrollTop === 0) {
      const previousScrollHeight = chatContainer.scrollHeight;
      loadMoreMessages(chatId, currentMsgHistoryPage + 1).then(() => {
        const newScrollHeight = chatContainer.scrollHeight;
        chatContainer.scrollTop = newScrollHeight - previousScrollHeight;
      });
    }
  };

  const updatePrivateChat = async (chatId)=>{
    try {
      const chatData = await fetchPrivateChat(chatId);
      if (chatData) {
        setSelectedChat(chatData.data.attributes);
      }
    } catch (error) {
      console.error(error.message);
    }
  }
  const updateChatHistory = async (chatId)=>{
    setCurrentMsgHistoryPage(1)
    setTotalMsgHistoryPage(null)
    try {
      const chatHis = await msgHistory(chatId,1); 
      const reversedChatHistory = chatHis.data.data.map(chatGroup => ({
        ...chatGroup,
        messages: chatGroup.messages.reverse() 
      }));
      if (chatHis) {
        setChatHistory(reversedChatHistory.reverse());
      }
    } catch (error) {
      console.error(error.message);
    }
  }

  const handleMessageReceived = (message) => {
    const messageData = message?.message?.data?.data?.attributes;
    if (messageData) {
      selectedChat?.id === messageData.chat_id && updatePrivateChat(messageData.chat_id) && markMessagesAsRead(messageData.chat_id)
      loadChatList() && updateChatHistory(selectedChat?.id);
    }else if(message?.message?.event?.event =="presence.update"){
      loadChatList()
    } else {
      // console.warn('Invalid WebSocket message structure', message);
    }
  };

  const sendMessageHandler = async (chatId, userMessage) => {
    if (userMessage) {
      if (isSending) return; 
    
      setIsSending(true); 
      try {
        await sendMessage(chatId, userMessage); 
        setUserMessage('');
        scrollToBottom();
        handleSelectChat(chatId);
        loadChatList();
        setCurrentMsgHistoryPage(1);
        setTotalMsgHistoryPage(null);
      } catch (error) {
      } finally {
        setIsSending(false); 
      }
    }

  };

  function handleChange(e) {
    const { name, value } = e.target;
    setInputValue({ ...inputValues, [name]: value });
    getGraph(value);
  }
  const formatCurrency = (value) => {
    return Number(value).toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).replace(/^(\D+)/, '');
  };
  const params = {
    headers: {
      "Content-Type": 'application/json',
      "Access-Control-Allow-Origin": '*',
      "token": localStorage.getItem('token')
    }
  };
  const dashboardPage = () => {
    axios.get(`${appConstant.API_URL}/dashboard/dashboards`, params)
        .then(res => {
          if (res.data.status === 200) {
            setDashboardData(res?.data);
          } else {
          }
        })
        .catch(error => {
        });
  }
  const getNotifications = () => {
    setIsNotificationLoading(true);
    axios.get(`${appConstant.API_URL}/notifications/notifications?page=1&limit=5`, params)
        .then(res => {
          setIsNotificationLoading(false);
          if (res?.data?.status === 200) {
            setNotifications(res?.data?.data);
          }else {
            setNotifications([]);
          }
        })
        .catch(error => {
          setIsNotificationLoading(false);
        });
  }
  const getGraph = (type = 'this_month') => {
    setIsGraphLoading(true);
    axios.get(`${appConstant.API_URL}/bx_block_dashboard/dashboard_graph?type=${type}`, params)
        .then(res => {
          setIsGraphLoading(false);
          if (res?.data?.status === 200) {
            const result = Object.keys(res.data.range).map((key) => [(key), res.data.range[key]]);
            let data = [];
            data.push(['Duration', 'Amount'])
            for(let i=0; i<result.length; i++){
              let res = result[i];
            if(type==="this_week" || type === "all"){
             const  currentdate = `${result[i][0]} ${Moment().year()}`
              res[0] = Moment(currentdate).format("YYYY-MM-DD")
            }else{
              res[0] = Moment((result[i])[0]).format("YYYY-MM-DD")
            }
              res[1] = (res[1] > 0) ? Number(res[1]): 0.00;
              data.push(res);
            }
           
            setGraphData(data);
          }else {
            
            const data = [
              ["Duration", "Amount"]
          ]
            setGraphData(data);
          }
        })
        .catch(error => {
          setIsGraphLoading(false);
        });

       
  }
  const options = {
    chart: {
      title: 'Sales and Expenses',
    },
    vAxis: {
      viewWindow: {
        min: 0, // Force the y-axis minimum to be zero
      },
    },
  };
  const handleSelectChat = async (chatId) => {
    setchatID(chatId);
    try {
      const chatHis = await msgHistory(chatId, 1);
      const reversedChatHistory = chatHis.data.data.length > 0 
        ? chatHis.data.data.map(chatGroup => ({
            ...chatGroup,
            messages: chatGroup.messages.reverse() 
          }))
        : [{ datetime: "No Messages", messages: [] }]; 
      setChatHistory(reversedChatHistory.reverse()); 
      const chatData = await fetchPrivateChat(chatId);
      await markMessagesAsRead(chatId);
      if (chatData) {
        setSelectedChat(chatData.data.attributes);
      }
    } catch (error) {
      if (error?.response?.data?.errors[0].message == "Recent message is not found") {
        setChatHistory([{ datetime: "No Messages", messages: [] }]); 
        const chatData = await fetchPrivateChat(chatId);
        setSelectedChat(chatData.data.attributes); 
      }
    }
  
    scrollToBottom();
    loadChatList(); 
  };
  
        
  const handeleCloseChat = () => {
      setSelectedChat();
      setCurrentMsgHistoryPage(1)
      setTotalMsgHistoryPage(null)
  };
  const userInputHandler = (e) => {
    setUserMessage(e.target.value);
  };
 
  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && selectedChat && userMessage.trim()) {
      sendMessageHandler(selectedChat.id, userMessage);
    }
  };
  return (
    <>
    <Header/>
    <div className='blank'></div>
      <div className='container py-4'>
        <div className='row'>
          <div className='col-md-3'>
            <Sidebar id="1"/>
          </div>
          <div className='col-md-9'>
            <div className='rightSide dashboard'>
              <div className='breadcrumbs'>
                  <span className='active-page'>Dashboard</span>
              </div>
              <div className="row mb-2 ">
                <div className="col-lg-7 dashboardMetrics"></div>
                <div className="col-lg-5  dashboardChat p-0">
                  {selectedChat ? (
                      <ChatSection
                        selectedChat={selectedChat}
                        chatHistory={chatHistory}
                        chatContainerRef={chatContainerRef}
                        userMessage={userMessage}
                        userInputHandler={userInputHandler}
                        handleKeyDown={handleKeyDown}
                        sendMessageHandler={sendMessageHandler}
                        handeleCloseChat={handeleCloseChat}
                        onlineStatus={usersOnlineStatus[selectedChat.name]}
                      />
                    ) : isLoading ? (
                      <LoadingSpinner />
                    ) : (
                      <>
                        <div className="dashboardChatHeader underscore">
                          <p data-testid="message-tab">Messages {unreadCount > 0 ? `(${unreadCount})` : ""}</p>
                          <img src="/images/message.jpg" alt="message Icon" />
                        </div>
                        <ul className="messagesListDashboard">
                       
    <li  
          className={`messageItemDashboard`}
          onClick={() => handleSelectChat(chatsList[0].attributes.id)}>
            {chatsList.length > 0 &&  <ChatSectionItem
            unreadMessages={chatsList[0].attributes.unread_count}
            username={chatsList[0].attributes.name}
            img={chatsList[0].attributes.image_url}
            isOnline={usersOnlineStatus[chatsList[0].attributes.name]}
            timestatus={chatsList[0].attributes}
            usermessage={chatsList[0].attributes?.last_message?.attributes?.message}
          />}</li>
  {chatsList && chatsList.length > 0 ? (
    chatsList.slice(1)
      .filter((chat) => chat.attributes && chat.attributes.last_message !== null)
      .map((chat) => (
        <li
          key={chat.attributes.id}
          className={`messageItemDashboard`}
          onClick={() => handleSelectChat(chat.id)}
        >
          <ChatSectionItem
            unreadMessages={chat.attributes.unread_count}
            username={chat.attributes.name}
            img={chat.attributes.image_url}
            isOnline={usersOnlineStatus[chat.attributes.name]}
            timestatus={chat.attributes}
            usermessage={chat.attributes?.last_message?.attributes?.message}
          />
        </li>
      ))
  ) : (
    <li className="emptyChatList">No Messages found</li>
  )}
</ul>

                        <div className="viewAllMessage">
                          <Link to="/messages">View All</Link>
                        </div>
                      </>
                  )}
                </div>
              </div>
              <div className='row mt-3'>
                <div className='col-lg-7 mb-2'>
                  <div className='row'>
                    <div className='col-sm-6 mb-2'>
                      <div className='revenue border'>
                        <img src="/images/piechart.png" alt="chart" />
                        <div className='title'>Total Revenue</div>
                        <div className='amount'>
                         {formatCurrency(dashboardData?.total_revenue)}
                          <span className='currency'>(SAR)</span>
                        </div>
                        <div className='duration'>Start date : {Moment(dashboardData?.start_date).format('DD-MM-YYYY')}</div>
                        <div className='title'>Monthly average: {formatCurrency(dashboardData?.monthly_avg)}</div>
                      </div>
                    </div>
                    <div className='col-sm-6 mb-2'>
                      <div className='border equipment_details'>
                        <img src="/images/mini-tractor.png" alt="icon" />
                        <div className='title'>Equipment</div>
                        <div className='number'>{dashboardData?.total_equipments}</div>
                        <div className='details'>Last updated on : {Moment(dashboardData?.last_updated_equipment_date).format('DD-MM-YYYY')}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-lg-5 mb-2'>
                  <div className='border p-0'>
                    {isNotificationLoading ? <LoadingSpinner/> :
                        <>
                          {notifications?.length > 0 && notifications?.map((result, i) =>
                              <>
                                <div className={!result?.attributes?.is_read ? 'buyer unread' : 'buyer'}>
                                  <div className='d-flex justify-content-start align-items-start'>
                                    <div className='profile-box'>
                                      <img src={result?.attributes?.image ? result?.attributes?.image : '/images/userr.png'} className='profile' alt="profile"/>
                                      {!result?.attributes?.is_read && <span className='green-dot'></span>}
                                    </div>
                                    <div className='buyer_details w-100'>
                                      <div className='d-flex justify-content-between'><span className='buyer-name' style={{textTransform: 'capitalize'}}>{result?.attributes?.full_name}</span>
                                        <span className='gray-text text-end'>{Moment(result?.attributes?.created_at).startOf('minutes').fromNow()}</span></div>
                                      <div className='order'>
                                        {result?.attributes?.contents}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                          )}
                        </>
                    }
                  </div>
                </div>
              </div>
              <div className='graph'>
              <div className='d-flex justify-content-between equipment_details mb-4'>
                <div className='title'>Revenue</div>
                <select name="type" className='select' value={inputValues.type} onChange={(e) => handleChange(e)} data-testid="select-option">
                  <option value="all">All</option>
                  <option value="this_year">This Year</option>
                  <option value="this_month">This Month</option>
                  <option value="this_week">This Week</option>
                </select>
                </div>
              {isGraphLoading ? <LoadingSpinner/> :
                <Chart
                    chartType="Bar"
                    width="100%"
                    height="400px"
                    data={graphData}
                    options={options}
                />
                  }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

export default Dashboard;

