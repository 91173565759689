import React, {useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import HireHeader from './HireHeader';
import HireFooter from './HireFooter';
import axios from 'axios';
import appConstant from '../shared/constant/constant.json';
import LoadingSpinner from '../shared/Loader';

function AboutUs({showFooter}) {
  useEffect(() => {
    aboutPage();
  }, []);
  const props = useParams();
  const [pageData, setPageData] = React.useState({});
  const [isLoading, setIsLoading] = useState(true);
  const params = {
    headers: {
      "Content-Type": 'application/json',
      "Access-Control-Allow-Origin": '*',
      "token": localStorage.getItem('token')
    }
  };
  const aboutPage = () => {
    axios.get(`${appConstant.API_URL}/bx_block_contact_us/about_us`, params)
        .then(res => {
          setIsLoading(false);
          if (res.data.status === 200) {
            setPageData(res?.data?.data);
          } else {
          }
        })
        .catch(error => {
          setIsLoading(false);
        });
  }
  const fontStyle = {
    fontFamily: "'Montserrat', sans-serif",
    color: ""
  };
  return (
      <>
        <HireHeader id="3"/>
        <div className='filter-blank'></div>
        <div className='about-us'>
          <div className='background-color'>
            <div className='breadcrumbs container mb-5 pt-3'>
              <div ><Link to="/home/hire-equipment"  data-testid="home-tab" >Home / </Link> <strong>About Us</strong></div>
            </div>
          </div>
          {isLoading ? <LoadingSpinner/> :
              <>
          <div className='container about-content'>
            <img className='about-banner' src={pageData?.image ? pageData?.image : "/images/about-banner.png"} alt='about banner'/>
            {/* <div className='row'>
              <div className='col-xl-12 col-lg-10 col-md-12'>
                <h6 className='tagline' style={{textAlign: 'center'}}>Our Story</h6>
                  <div dangerouslySetInnerHTML={{__html: pageData?.our_story}} style={{textAlign: 'justify'}}></div>
              </div>
            </div> */}

            <div class="row">
            <div class="col-lg"></div>
            <div class="col-lg-6 col-md-8">
                  <h6 className='tagline' style={{textAlign: 'center'}}>Our Story</h6>
                  <div dangerouslySetInnerHTML={{__html: pageData?.our_story}} style={{textAlign: 'justify'}}></div>
            </div>
            <div class="col-lg"></div>
            </div>
            {/* <div className='row'>
              <div className='col-xl-12 col-lg-10 col-md-12 mb-5'>
                <h6 className='tagline'>Our Story</h6>
                  <div dangerouslySetInnerHTML={{__html: pageData?.our_story}}></div>
              </div>
              <div className='col-xl-3 col-lg-1 d-none d-lg-block'></div>
              <div className='col-xl-5 col-lg-6 col-md-6 mb-5'>
                <ol>
                  <li dangerouslySetInnerHTML={{ __html: pageData?.content_1 }}>
                  </li>
                  <li dangerouslySetInnerHTML={{ __html: pageData?.content_2 }}>
                  </li>
                  <li dangerouslySetInnerHTML={{ __html: pageData?.content_3 }}>
                  </li>
                </ol>
              </div>
              <div className='col-lg-7 mb-5'>
                <h6 className='title'>Our Mission</h6>
                  <p dangerouslySetInnerHTML={{ __html: pageData?.our_mission }}></p>
              </div>
              <div className='col-lg-5'>
                <img className='about-banner mb-0' src={pageData?.our_mission_image ? pageData?.our_mission_image : "/images/mission.png"} alt='mission'/>
              </div>
            </div> */}
          </div>
              </>
          }
        </div>
        {!showFooter && <HireFooter/>}
      </>
  )
};

export default AboutUs;