import React, {useEffect, useState, useContext} from 'react';
import HireHeader from './HireHeader';
import HireFooter from './HireFooter';
import {Link} from 'react-router-dom';
import axios from 'axios';
import appConstant from '../shared/constant/constant.json';
import Moment from 'moment';
import LoadingSpinner from '../shared/Loader';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from "@material-ui/core";
import { CountContext } from './CountContext';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const useStyles = makeStyles({
    myAlert: {
      backgroundColor: "#ff8d27",
      boxShadow: 'none',
      fontFamily: 'Montserrat',
    }
  });

  const customSnackbarStyles = {
    marginTop: '7rem',
  };

function Cart() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [snackBar, setSnackBar] = React.useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [isRequestQuoteLoading, setIsRequestQuoteLoading] = useState(false);
    const [startDates, setStartDates] = useState('')
    const [isDisabled,setIsDisable]=useState(false)
    const { favCount, favouriteCount, cartsTotal, cartCount } = useContext(CountContext);
    const addQuantity = (e, i, attributeName) => {
        setSnackBar("Quantity Increased")
        setOpen(true);
        const {name, value} = e.target;
        let data = [...cartItems];
        data[i].attributes[attributeName] = (parseInt(data[i].attributes[attributeName]) +1);
        setCartItems(data);
        updateCart(data[i]);
    }
    const removeQuantity = (e, i, attributeName) => {
        const {name, value} = e.target;
        let data = [...cartItems];
        if(parseInt(data[i].attributes[attributeName]) > 1){
            data[i].attributes[attributeName] = (parseInt(data[i].attributes[attributeName]) - 1);
            setCartItems(data);
            updateCart(data[i]);
            setSnackBar("Quantity Decreased")
            setOpen(true);
        }
    }
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
    };

    useEffect(() => {
        cartList();
    }, []);

    const [inputValues, setInputValue] = useState({
        deadline: Moment().add(3, 'd').format('YYYY-MM-DD')
    });
    const cartItemValue = {
        start_date : '',
        end_date : '',
        orderId: '',
        quantity: 1
    }
    const handleChange = (e) => {
        const {name, value} = e.target;
        setInputValue({...inputValues, [name]: value});
    }
    const changeCartValue = (e, i, attributeName) => {
            const {name, value} = e.target;
            let data = [...cartItems];
            data[i].attributes[attributeName] = e.target.value;
            setCartItems(data);
            setStartDates(value)
            updateCart(data[i]);
    }

    const [cartItems, setCartItems] = React.useState({});
    const [resultMessage, setResultMessage] = React.useState('');
    const params = {
        headers: {
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": '*',
            "token": localStorage.getItem('token')
        }
    };
    const cartList = () => {
        axios.get(`${appConstant.API_URL}/bx_block_order_management/cart_items`, params)
            .then(res => {
                setIsLoading(false);
                if (res.data.status === 200) {
                    setCartItems(res.data.data);
                }else {
                    setResultMessage(res.data.message);
                    setCartItems([]);
                }
            })
            .catch(error => {
                setIsLoading(false);
            });
    }
    const deleteCart = (cartId, orderId) => {
        const deleteCartData = {
            id: cartId,
            order_id: orderId
        }
        axios.post(`${appConstant.API_URL}/bx_block_order_management/cart_items/delete_cart`, deleteCartData, params)
            .then(res => {
                setIsLoading(false);
                if (res?.status === 200) {
                    setSnackBar("Equipment removed from cart succesfully")
                    setOpen(true)
                    setResultMessage(res?.data?.message);
                    cartList();
                    cartCount();
                }
            })
            .catch(error => {
                setIsLoading(false);
            });
    }
    const requestForQuote = (e, orderId) => {
        setIsRequestQuoteLoading(true);
        e.preventDefault();
        const requestForQuoteData = {order_id: orderId, deadline_of_proposal: inputValues.deadline}
        axios.post(`${appConstant.API_URL}/bx_block_order_management/orders/submit_quotes`, requestForQuoteData, params)
            .then(res => {
                setIsRequestQuoteLoading(false);
                if (res.data.status === 200) {
                    setSnackBar('Quote requested successfully')
                    setOpen(true)
                    setResultMessage('');
                    setCartItems([]);
                    cartCount();
                }else {
                    setResultMessage(res.data.message);
                }
            })
            .catch(error => {
                setIsRequestQuoteLoading(false);
            });
    }
    const updateCart = (cartItemObj) => {
        const {quantity, start_date, end_date, order_id, id} = cartItemObj.attributes
        if(Moment(start_date).format("YYYY-MM-DD") > Moment(end_date).format("YYYY-MM-DD")) {
            setIsDisable(true)
            setResultMessage("Start Date should not be greater than End Date");
        }
        else {
        setResultMessage('')
        setIsDisable(false)
        const updateCartData = {
            quantity: quantity,
            start_date: start_date,
            end_date: end_date,
            order_id: order_id
        }
        axios.put(`${appConstant.API_URL}/bx_block_order_management/cart_items/${id}`, updateCartData, params)
            .then(({data}) => {
                if (data.status === 200) {
                    cartList();
                    cartCount();
                }else {
                    setResultMessage(data.message);
                }
            })
            .catch(error => {
                setIsLoading(false);
            });
        }
    }

    return (
        <>
            <HireHeader/>
            <div className='filter-blank'></div>
            <div className='cart home-content'>
                {isLoading ? <LoadingSpinner/> :
                    <div className='container'>
                        <div className='breadcrumbs'>
                            <div><Link to="/home/hire-equipment"> Home /</Link> <strong>Cart</strong></div>
                            <div><label>Deadline for Proposals</label>
                                <input onChange={(e) => handleChange(e)} name='deadline' type="date" min={Moment().format('YYYY-MM-DD')} value={inputValues.deadline} onFocus={(e)=>e.target.blur()} onKeyPress={(e) => e.preventDefault()} onKeyDown={(e) => e.preventDefault()}/>
                            </div>
                        </div>
                        <form>
                            {resultMessage && <p className='formErrors'>{resultMessage}</p>}
                            <div className="table-responsive alyya-table mb-3">
                                <table className="table table-borderless">
                                    <thead>
                                    <tr>
                                        <th className='text-start'>Equipment</th>
                                        <th className='text-start'>Type / Capacity</th>
                                        <th>Duration</th>
                                        <th>Quantity</th>
                                        <th>Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {cartItems?.length > 0 && cartItems?.map((result, i) =>
                                        <>
                                            <tr key={i}>
                                                <td className='text-start'>
                                                    <input type='hidden' name='orderId'
                                                           value={result.attributes?.order_id}
                                                           onChange={(e) => handleChange(e)}/>
                                                           
                                                <Link to={"/home/hire-equipment/view-equipment-detail/" + result?.attributes?.equipment_id} state={{cartItemId:result?.id}}>
                                                    <div className='d-flex align-items-center'>
                                                        <img
                                                            src={result?.attributes?.images?.length > 0 && result?.attributes?.images[0].url}
                                                            className="" alt="equipment"/>
                                                        <div>
                                                            <strong
                                                                className='d-block'>{result?.attributes?.equipment?.product_name}</strong>
                                                            <span
                                                                className='d-block mt-2'>{result?.attributes?.equipment?.model} model</span>
                                                            <span className='d-block mt-2'>{result?.attributes?.organisation_name}  </span>
                                                        </div>
                                                    </div>
                                                </Link>
                                                </td>
                                                <td className='text-start'>
                                                    <strong
                                                        className='d-block mt-2'>{result?.attributes?.sub_category_name}</strong>
                                                    <span
                                                        className='d-block mt-2'>{result?.attributes?.capacity} Capacity</span>
                                                    <span
                                                        className='d-block mt-2'>Zone <strong>{result?.attributes?.zone}</strong></span>
                                                </td>
                                                <td className='text-start'>
                                                    <div
                                                        className='d-flex justify-content-between align-items-center input-date'>
                                                        <label className='d-block'>Start Date </label><input
                                                        onChange={(e) => {changeCartValue(e, i, 'start_date')}}
                                                        type="date" name="start_date"
                                                        value={Moment(result?.attributes?.start_date).format('YYYY-MM-DD')}
                                                        min={Moment().format('YYYY-MM-DD')} style={{ width: '100%', maxWidth: '160px' }} onFocus={(e)=>e.target.blur()} onKeyPress={(e) => e.preventDefault()} onKeyDown={(e) => e.preventDefault()}/>
                                                    </div>
                                                    <div
                                                        className='d-flex justify-content-between align-items-center mt-2 input-date'>
                                                        <label className='d-block'>End Date </label><input
                                                        onChange={(e) => changeCartValue(e, i, 'end_date')} type="date"
                                                        name="end_date"
                                                        value={Moment(result?.attributes?.end_date).format('YYYY-MM-DD')}
                                                        min={Moment(result?.attributes?.start_date).format('YYYY-MM-DD') ?? startDates} 
                                                        max="2099-04-05" onFocus={(e)=>e.target.blur()} onKeyPress={(e) => e.preventDefault()} onKeyDown={(e) => e.preventDefault()}/>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='quantity-change'>
                                                        <button className='input-number-change' type='button'
                                                                onClick={(e) => removeQuantity(e, i, 'quantity')}>-
                                                        </button>
                                                        <input className="input-number" name="quantity"
                                                               type="text"
                                                               value={result?.attributes?.quantity ? result?.attributes?.quantity : 1} readOnly/>
                                                        <button className='input-number-change' type='button'
                                                                onClick={(e) => addQuantity(e, i, 'quantity')}>+
                                                        </button>
                                                       
                                                    </div>
                                                </td>
                                                <td>
                                                    <button className='delete-button' type='button' onClick={() => {
                                                        deleteCart(parseInt(result?.id), result?.attributes?.order_id)
                                                    }}><img src="/images/trash.png" className="" alt="equipment"/>Remove
                                                    </button>
                                                </td>
                                            </tr>
                                        </> 
                                    )}
                                    </tbody>
                                </table>
                                <Snackbar open={open} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "right"}} style={customSnackbarStyles} onClose={handleClose}>
                                    <Alert onClose={handleClose} severity="success" className={classes.myAlert}>
                                        {snackBar}
                                    </Alert>
                                </Snackbar>
                            </div>
                            {cartItems?.length > 0 &&
                            <>
                                {isRequestQuoteLoading ? <div className='position-relative'><button className="submit-btn  mb-0" disabled={true} type="button">Request for Quote</button> <img className='position-absolute' style={{"top" : "30%", "right" : "41%"}} src='/images/default.gif' width={'16px'} height={'16px'}/></div> :
                                    <button className="submit-btn mb-0" type="button" disabled={isDisabled} onClick={(e) => {
                                        requestForQuote(e, cartItems[0]?.attributes?.order_id)
                                    }}>Request for Quote</button>
                                }
                            </>
                            }
                        </form>

                    </div>
                }
            </div>
            <HireFooter/>
        </>
    )
};

export default Cart;
