import React, { useRef, useEffect, useState, useContext } from 'react';
import HireHeader from './HireHeader';
import HireFooter from './HireFooter';
import { Link, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import appConstant from '../shared/constant/constant.json';
import Moment from 'moment';
import Pagination from '../shared/Pagination';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from "@material-ui/core";
import { CountContext } from './CountContext';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles({
  myAlert: {
    backgroundColor: "#ff8d27",
    boxShadow: 'none',
    fontFamily: 'Montserrat',
  },
  formLabel: {
    position: 'absolute',
    left: '10px',
    top: '10px',
  },
  formRelative: {
    position: 'relative',
  },
  formLabelpadding: {
    paddingLeft: '63px !important',
  }
});

const customSnackbarStyles = {
  marginTop: '7rem',
};

function HireEquipment(props) {
  const classes = useStyles();
  let [searchParams, setSearchParams] = useSearchParams();
  const ref = useRef();
  const ref1 = useRef();
  useEffect(() => {
    const sort = sessionStorage.getItem('sort')
    let value = ''
    if (sort === 'H-L') value = 'false'
    if (sort === 'L-H') value = 'true'
    setInputValue({ ...inputValues, sort: value });
    equipmentList(1, value);
    filterList();
    getLocations();
    if (props.item === 'refresh') {
      equipmentList(1, value);
    }
  }, []);


  const [open, setOpen] = React.useState(false);
  const [snackBar, setSnackBar] = React.useState('');
  const [equipments, setEquipments] = React.useState({});
  const [categories, setCategories] = React.useState([]);
  const [subCategories, setSubCategories] = React.useState([]);
  const [filterCategories, setFilterCategories] = React.useState([]);
  const [subCategoriesData, setSubCategoriesData] = React.useState([]);
  const [locations, setLocations] = React.useState([]);
  const [accounts, setAccounts] = React.useState([]);
  const [paginationPages, setPaginationPages] = React.useState([]);
  const [filterValidationData, setFilterValidationData] = React.useState([]);
  const [selectedOption, setSelectedOption] = useState('');
  const { favCount, favouriteCount, cartsTotal, cartCount } = useContext(CountContext);
  const [startDateError, setStartDateError] = useState('');
  const [isAddingToCart, setIsAddingToCart] = useState(false);
  const [inputValues, setInputValue] = React.useState({
    category: '',
    subCategory: '',
    location: '',
    startDate: '',
    endDate: '',
    suppliers: '',
    sort: ''
  });
  const params = {
    headers: {
      "Content-Type": 'application/json',
      "Access-Control-Allow-Origin": '*',
      "token": localStorage.getItem('token')
    }
  };
  const equipmentList = (page, sort = '', isCleared = false) => {

    let getParams = '';
    let search = searchParams?.get('s') ? searchParams?.get('s') : '';
    getParams = `?category_id=&sub_category_id=&base_location=&start_date=&end_date=&account_id=&low_to_high=&search=${search}&page=1&limit=9`;
    if (!isCleared) {
      getParams = `?category_id=${inputValues.category}&sub_category_id=${inputValues.subCategory}&base_location=${inputValues.location}&start_date=${inputValues.startDate}&end_date=${inputValues.endDate}&account_id=${inputValues.suppliers}&search=${search}&low_to_high=${sort}&page=${page}&limit=9`;
    }
    axios.get(`${appConstant.API_URL}/bx_block_catalogue/equipments${getParams}`, params)
      .then(res => {
        // window.scrollTo(0, 0);
        if (res.data.status === 200) {
          setEquipments(res.data);
          const row = [];
          for (var i = 1; i <= parseInt(res.data.total_pages); i++) {
            row.push(i);
          }
          setPaginationPages(row);
        } else {
          const data = {
            data: []
          }
          setEquipments(data);
        }
      })
      .catch(error => {
      });
  }
  const pageChange = (page) => {
    const sort = sessionStorage.getItem('sort')
    let value = ''
    if (sort === 'H-L') value = 'false'
    if (sort === 'L-H') value = 'true'
    equipmentList(page, value);
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  const filterList = () => {
    axios.get(`${appConstant.API_URL}/bx_block_catalogue/equipments/filter_data`, params)
      .then(res => {
        if (res.data.status === 200) {
          setCategories(res.data.category);
          // setSubCategoriesData(res.data.sub_category);
          // setLocations(res.data.base_location);
          setAccounts(res.data.accounts);
          // getSubCategory(res.data?.category[0]?.id);
        } else {
        }
      })
      .catch(error => {
      });
  }

  useEffect(() => {
    if (inputValues.sort !== '') {
      const sort = inputValues.sort === 'true' ? 'L-H' : 'H-L';
      sessionStorage.setItem('sort', sort);
      equipmentList(1, inputValues.sort);
    } else {
      sessionStorage.setItem('sort', '');
      equipmentList(1, '');
    }
  }, [inputValues.sort]);

  const getSubCategory = (e) => {
    const { name, value } = e.target;
    setInputValue({ ...inputValues, [name]: value });
    filterValidation(value, name);
  }
  const getLocations = () => {
    axios.get(`${appConstant.API_URL}/bx_block_categories/filter_locations`, params)
      .then(res => {
        if (res.data.status === 200) {
          setLocations(res.data.locations);
        } else {
        }
      })
      .catch(error => {
      });
  }
  const addToCart = (equipment, page) => {
    let addToCartData = {
      equipment_id: equipment?.id,
      quantity: 1,
      capacity_id: equipment?.attributes?.capacity_id,
      base_location_id: equipment?.attributes?.base_location_id,
      zone: equipment?.attributes?.base_location,
    }
    if (inputValues?.startDate && inputValues?.endDate) {
      addToCartData = {
        ...addToCartData,
        start_date: inputValues?.startDate,
        end_date: inputValues?.endDate
      }

    }
    if (!isAddingToCart) {
      setIsAddingToCart(true);
      axios.post(`${appConstant.API_URL}/bx_block_order_management/orders/add_to_cart`, addToCartData, params)
        .then(res => {
          if (res.data.status === 200) {
            setSnackBar("Item added to cart successfully")
            setOpen(true);
            const sort = sessionStorage.getItem('sort')
            let value = ''
            if (sort === 'H-L') value = 'false'
            if (sort === 'L-H') value = 'true'
            equipmentList(page, value);
            cartCount();
            setIsAddingToCart(false);
          } else {
            // setResultMessage(res.data.message);
          }
        })
        .catch(error => {
          // props.changeLogin(true);
        });
    }

  }
  function handleChange(e) {
    const { name, value } = e.target;
    setInputValue({ ...inputValues, [name]: value });


  }

  function sortChange(e, page) {
    const { name, value } = e.target;
    // if(value==='') {
    //     sessionStorage.setItem("sort", 'R')
    // }
    // else if(value === 'true') {
    //     sessionStorage.setItem("sort", 'L-H')
    // }
    // else if(value === 'false') {
    //     sessionStorage.setItem("sort", 'H-L')
    // }
    setInputValue({ ...inputValues, [name]: value });
    // equipmentList(1, value);
  }
  const searchByFilters = (e) => {
    e.preventDefault();
    if (inputValues.startDate > inputValues.endDate) {
      setStartDateError('Start date should not be greater than end date');
    }
    if (inputValues.startDate <= inputValues.endDate) {
      setStartDateError('');
    }
    equipmentList(1);
  }
  const clearFilters = (e) => {
    const clearInputData = {
      category: '',
      subCategory: '',
      location: '',
      startDate: '',
      endDate: '',
      suppliers: '',
      sort: ''
    };
    setInputValue(clearInputData);
    setSubCategories([]);
    filterList();
    equipmentList(1, '', true);
  }

  const removeFromCart = (equipment, page) => {
    const params = {
      headers: {
        "Content-Type": 'application/json',
        "Access-Control-Allow-Origin": '*',
        "token": localStorage.getItem('token')
      }
    };
    axios.get(`${appConstant.API_URL}/bx_block_order_management/cart_items`, params)
      .then(res => {

        if (res.data.status === 200) {
          setSnackBar("Item removed from cart successfully")
          setOpen(true);
          const order = res.data.data.filter(each => each.attributes.equipment_id === Number(equipment.id))
          if (order.length) {
            order.forEach(cart => {
              // const cart = order[0]
              const deleteCartData = {
                id: cart.id,
                order_id: cart.attributes.order_id
              }
              axios.post(`${appConstant.API_URL}/bx_block_order_management/cart_items/delete_cart`, deleteCartData, params)
                .then(res => {

                  if (res?.status === 200) {

                    const sort = sessionStorage.getItem('sort')
                    let value = ''
                    if (sort === 'H-L') value = 'false'
                    if (sort === 'L-H') value = 'true'
                    equipmentList(page, value);
                    cartCount();

                  } else {
                  }
                })
                .catch(error => {

                });
            });
          }
        } else {

        }
      })
      .catch(error => {

      });
  }
  const filterValidation = (value, type) => {
    let filterCategory = inputValues.category;
    // let filterSubcategory = inputValues.subCategory;
    let filterSubcategory = '';
    let filterLocation = inputValues.location;
    let filterVendor = inputValues.suppliers;
    if (type === 'category') {
      filterCategory = value;
    }
    if (type === 'subcategory') {
      filterSubcategory = value;
    }
    if (type === 'location') {
      filterLocation = value;
    }
    if (type === 'suppliers') {
      filterVendor = value;
    }
    const getParams = `?category_id=${filterCategory}&sub_category_id=${filterSubcategory}&base_location=${filterLocation}&account_id=${filterVendor}`;
    axios.get(`${appConstant.API_URL}/bx_block_catalogue/equipments/dependent_filter_params${getParams}`, params)
      .then(res => {
        if (res.data.status === 200) {
          setSubCategoriesData(res.data.sub_category);
          if (filterCategory) {
            setAccounts(res.data.accounts)
          }

          // const data = res.data.sub_category[Number(inputValues.category)]
          if (res.data.sub_category.length > 0) {
            setSubCategories((res.data.sub_category[0])[1])
          } else {
            setSubCategories([])
          }
        } else {
        }
      })
      .catch(error => {
      });
  }
  useEffect(() => {
    if (!inputValues.category) {
      return
    }
    filterValidation(inputValues.category, 'category')

  }, [])



  return (
    <>
      <HireHeader id="1" />
      <div className='filter-blank'></div>
      <div className='hire-equipment home-content'>
        <div className='container'>
          <div className='breadcrumbs'>
            <div data-testid="heading-title">Home / <strong data-testid="hire-equipment">Hire Equipment</strong></div>
          </div>
          <div className='row'>
            <div className='col-lg-3 mb-4'>
              <h5 className='title'>Filters</h5>
              <form className='filters' data-testid="form-container">
                <label htmlFor="category" className="label d-block mb-2">Categories</label>
                <select className="inputs category d-block w-100 mb-3" value={inputValues.category} onChange={(e) => { getSubCategory(e) }} id="category" name="category">

                  <option key='category_all' value=''>Select Category</option>
                  {categories?.length > 0 && categories?.map((result, i) =>
                    <>
                      <option key={result.id} value={result.id}>{result.name}</option>

                    </>
                  )}
                </select>
                <select className="inputs category d-block w-100 mb-3" id="subCategory" name="subCategory" onChange={(e) => handleChange(e)}>
                  <option key='subcategory_all' value=''>Select Sub Category</option>
                  {subCategories?.length > 0 && subCategories?.map((result, i) =>
                    <>
                      <option key={result.id} value={result.id}>{result.name}</option>
                    </>
                  )}
                </select>
                <label htmlFor="location" className="label d-block mb-2">Location</label>
                <select className="inputs d-block w-100 mb-3" id="location" value={inputValues.location} onChange={(e) => { handleChange(e); filterValidation(e.target.value, 'location'); }} name="location">
                  <option key='location_all' value=''>Select Location</option>
                  {locations?.length > 0 && locations?.map((result, i) =>
                    <>
                      <option value={result?.id} key={i}>{result?.name}</option>
                    </>
                  )}
                </select>
                <label htmlFor="period" className="label d-block mb-2">Work Period</label>

                <div className={classes.formRelative}>
                  <label className={`formdd ${classes.formLabel}`}>From</label>
                  <input type="date" className={`inputs d-block date w-100 mb-2 ${classes.formLabelpadding}`} min={Moment().format('YYYY-MM-DD')} value={inputValues.startDate} name="startDate" onChange={(e) => handleChange(e)} id="startDate" placeholder="Start Date" onFocus={(e) => e.target.blur()} onKeyPress={(e) => e.preventDefault()} onKeyDown={(e) => e.preventDefault()} />
                  <div className="col-12">
                    <div className="max-width-column">
                      {startDateError === "Start date should not be greater than end date" && <span className="error-message pb-2">{startDateError}</span>}
                    </div>
                  </div>
                </div>
                <div className={classes.formRelative}>
                  <label className={classes.formLabel}>To</label>
                  <input type="date" className={`inputs d-block date w-100 mb-3 ${classes.formLabelpadding}`} min={inputValues.startDate} value={inputValues.endDate} name="endDate" onChange={(e) => handleChange(e)} id="endDate" placeholder="End Date" onFocus={(e) => e.target.blur()} onKeyPress={(e) => e.preventDefault()} onKeyDown={(e) => e.preventDefault()} />

                </div>

                <label htmlFor="suppliers" className="label d-block mb-2">Suppliers</label>
                <select className="inputs d-block w-100 mb-4" value={inputValues.suppliers} onChange={(e) => { handleChange(e); filterValidation(e.target.value, 'suppliers'); }} id="suppliers" name="suppliers">
                  <option key='All' value=''>Select Supplier</option>
                  {accounts?.length > 0 && accounts?.map((result, i) =>
                    <>
                      <option key={result.id} value={result.id}>{result.full_name}</option>
                    </>
                  )}
                </select>
                <button className='black-button w-100 mb-4' onClick={(e) => { searchByFilters(e) }}>Apply Filters</button>
                <button className='clear-button w-100' type="button" onClick={(e) => { clearFilters(e) }}>Clear all Filters</button>
              </form>
            </div>
            <div className='col-lg-9'>
              {searchParams?.get('s') && <h4><strong>Search result for:</strong> {searchParams?.get('s')}</h4>}
              <div className='text-end mb-3 filters'>
                <label htmlFor="sort" className="label">Sort by</label>
                <select className="inputs sort" id="sort" name="sort" value={inputValues.sort} onChange={(e) => sortChange(e)}>
                  <option key='Relevance' value='' >Relevance</option>
                  <option key='Low to High Price' value='true'>Low to High Price</option>
                  <option key='High to Low Price' value='false'>High to Low Price</option>

                </select>
              </div>
              <div className='row mb-5'>

                {equipments?.data?.length > 0 && equipments?.data?.map((result, i) =>
                  <>
                    <div className='col-sm-6 col-md-4' key={i} data-testid={i}>
                      <div className="card alyya-card mb-3" data-testid="cardContainer">
                        <Link to={"/home/hire-equipment/view-equipment-detail/" + result.id}>
                          <div className='position-relative'>
                            <img className="card-img" src={result.attributes.images && result.attributes.images[0]?.url ? result.attributes.images[0]?.url : '/images/login_banner.png'} alt={result.attributes.product_name} />
                            <div className='position-absolute modal-tag'>{result?.attributes?.model} model</div>
                          </div>
                        </Link>

                        <div className="card-body">
                          <Link to={"/home/hire-equipment/view-equipment-detail/" + result?.id} className="card-title equipment-title" style={{ textTransform: 'capitalize' }}>{result?.attributes?.product_name}</Link>

                          {result?.attributes?.capacity?.value && <p className="card-text" data-testid="capacity-value">{result?.attributes?.capacity?.value} Capacity</p>}
                          <p className="card-text desc"><strong style={{ textTransform: 'capitalize' }}>By Equipment- <span className='card-text' testID="by-equipment">{result?.attributes?.organisation_name}</span></strong></p>
                          <p className="card-text desc mb-3"><strong> SAR {Number(result.attributes.price_per_week).toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          })}
                            /week
                          </strong></p>
                          {(!result?.attributes?.added_cart) && <button className='black-button' id='addToCartBtn' onClick={() => { addToCart(result, equipments.current_page) }} disabled={isAddingToCart}>Add to cart</button>}
                          {(result?.attributes?.added_cart) && <button className='orange-button' id='addedCartBtn' onClick={() => removeFromCart(result, equipments.current_page)}>Remove</button>}
                          <Snackbar open={open} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={customSnackbarStyles} onClose={handleClose}>
                            <Alert onClose={handleClose} severity="success" className={classes.myAlert}>
                              {snackBar}
                            </Alert>
                          </Snackbar>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {equipments?.data?.length == 0 &&
                  <h2 key='no'>
                    Not Found
                  </h2>
                }
              </div>
              {equipments?.data?.length > 0 &&
                <Pagination
                  className="pagination-bar"
                  currentPage={equipments?.current_page}
                  totalCount={equipments?.total_pages}
                  pageSize={1}
                  onPageChange={page => pageChange(page)}
                />
              }
            </div>
          </div>
        </div>

      </div>
      <HireFooter />
    </>
  )
};

export default HireEquipment;