import React, {useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import HireHeader from './HireHeader';
import HireFooter from './HireFooter';
import axios from 'axios';
import appConstant from '../shared/constant/constant.json';
import LoadingSpinner from '../shared/Loader';

function FaqsHome() {
    useEffect(() => {
        FaqPage();
    }, []);
    const props = useParams();
    const [faqs, setFaqs] = React.useState({});
    const [isLoading, setIsLoading] = useState(true);
    const params = {
        headers: {
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": '*',
            "token": localStorage.getItem('token')
        }
    };
    const FaqPage = () => {
        setIsLoading(true);
        axios.get(`${appConstant.API_URL}/bx_block_contact_us/faqs`, params)
            .then(res => {
                setIsLoading(false);
                if (res.data.status === 200) {
                    setFaqs(res?.data?.data);
                } else {
                }
            })
            .catch(error => {
            });
    }
    return (
        <>
            <HireHeader/>
            <div className='filter-blank'></div>
            <div className='faqs'>
                <div className='background-color'>
                    <div className='breadcrumbs container mb-5 pt-3'>
                        <div><Link to="/home/hire-equipment">Home / </Link> <strong>FAQs</strong></div>
                    </div>
                    <div className='container title-box'>
                        <h6 className='tagline'>faqs</h6>
                        <h3 className='title'>Frequently Asked Questions</h3>
                        <p className='desc' testID="desc">Have questions? We are here to help</p>
                    </div>
                </div>
                <div className='container py-5'>
                    {/*<form className="d-flex searchBox">*/}
                    {/*  <button className="search" type="submit"><img src="/images/search.png" alt="search" /></button>*/}
                    {/*  <input type="text" name="s" placeholder="Search"/>*/}
                    {/*</form>*/}

                    <div id="accordion">
                        {isLoading ? <LoadingSpinner/> :
                            <>
                                {faqs?.length > 0 && faqs?.map((result, i) =>
                                    <>
                                        <div className="card">
                                            <a className={i !== 0 ? "card-header collapsed" : "card-header"} data-bs-toggle="collapse" href={"#collapse" + i}> <span dangerouslySetInnerHTML={{__html: result.question}}></span></a>
                                            <div id={"collapse" + i} className={i === 0 ? "collapse show" : "collapse"} data-bs-parent="#accordion">
                                                <div className="card-body" ><span dangerouslySetInnerHTML={{__html: result.answer}}></span></div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </>
                        }
                    </div>


                    <h5 className='bottom-title text-center'>Still have questions?</h5>
                    <h6 className='desc text-center'>Can’t find the answer you are looking for? Please  <Link className='links' to='/home/contact-us'>contact us</Link></h6>
                </div>
            </div>
            <HireFooter/>
        </>
    )
};

export default FaqsHome;