import React, { useEffect, useState } from 'react';
import HireHeader from './HireHeader';
import HireFooter from './HireFooter';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import appConstant from '../shared/constant/constant.json';

function ContactUs({ showFooter }) {
  useEffect(() => {
    contactPage();
    userDetails()
  }, []);
  const props = useParams();
  const [contactUs, setContactUs] = React.useState({});
  const [resultMessage, setResultMessage] = React.useState('');
  const [errBorder, seterrBorder] = React.useState(false);
  const [errorBorderFullName, seterrorBorderFullName] = React.useState(false);
  const [errBorderEmail, seterrBorderEmail] = React.useState(false);
  const [errBorderText, seterrBorderText] = React.useState(false);
  const params = {
    headers: {
      "Content-Type": 'application/json',
      "Access-Control-Allow-Origin": '*',
      "token": localStorage.getItem('token')
    }
  };
  const userDetails = () => {
    axios.get(`${appConstant.API_URL}/account_block/account_details`, params)
      .then(res => {
        if (res?.data?.data) {
          let response = res?.data?.data
          let phoneNumber="";
          let regeX=/^(?:966|0)?\d{9}$/
          if(regeX.test(response.attributes.phone_number)){
            if(response.attributes.phone_number.toString().startsWith(966)){
              phoneNumber= "+" + response.attributes.phone_number
            }else{
              phoneNumber= response.attributes.phone_number.toString()
            }
          }
          setInputValue({ ...inputValues, fullname: response.attributes.full_name, email: response.attributes.email, number:phoneNumber })
        }
      })
  }

  const contactPage = () => {
    axios.get(`${appConstant.API_URL}/bx_block_contact_us/contacts?id=1`, params)
      .then(res => {
        if (res.data.status === 200) {
          setContactUs(res?.data?.data);
        } else {
        }
      })
      .catch(error => {
      });
  }
  const [inputValues, setInputValue] = useState({
    fullname: '',
    email: '',
    number: '',
    message: ''
  });
  const [validation, setValidation] = useState({
    fullname: '',
    email: '',
    number: '',
    message: ''
  });

  const checkValidation = () => {
    let errors = JSON.parse(JSON.stringify(validation));

    //fullname validation
    const alphabetCond = /^(?! )[\w ]+$/;
    if (!inputValues.fullname) {
      errors.fullname = 'Full Name is required';
      seterrorBorderFullName(true)
    } else if (!inputValues.fullname.match(alphabetCond)) {
      errors.fullname = 'Please enter valid name';
      seterrorBorderFullName(true)
    } else if (!inputValues.fullname.charAt(0) === ' ') {
      errors.fullname = "Full name can't be blank";
    }
    else {
      errors.fullname = '';
      seterrorBorderFullName(false)
    }
    //number validation
    const numberCond = /^(?:\+966|0)?\d{9}$/; 
    console.log(typeof inputValues.number,"dddddd")
    if (!inputValues.number.trim()) {
      errors.number = 'Number is required';
      seterrBorder(true)
    } else if (!inputValues.number.match(numberCond)) {
      errors.number = "Please enter 9 digit or 0 followed by 9 digit or +966 followed by 9 digit";
      seterrBorder(true)
    } else if (inputValues.number.length === 9 && inputValues.number.toString().charAt(0) === '0') {
      errors.number = 'Please enter valid number 1';
      seterrBorder(true)
    }
    else {
      errors.number = '';
      seterrBorder(false)
    }

    // email validation
    const emailCond = /^[a-zA-Z0-9.!#$%&’*+=?^_`{|}~-]+@[a-zA-Z0-9_-]+(?:\.[a-zA-Z0-9-]{2,})*$/;
    if (!inputValues.email) {
      errors.email = 'Email is required';
      seterrBorderEmail(true)
    } else if (!String(inputValues.email).match(emailCond)) {
      errors.email = `Please enter valid Email address`;
      seterrBorderEmail(true)
    } else {
      errors.email = '';
      seterrBorderEmail(false)
    }

    //message validation
    const msgCond = /^(?! )[\w ]+$/;
    if (!inputValues.message) {
      errors.message = 'Message is required';
      seterrBorderText(true)
    } else if (!inputValues.message.match(msgCond)) {
      errors.message = 'Please enter valid message';
      seterrBorderText(true)
    }
    else {
      errors.message = '';
      seterrBorderText(false)
    }

    setValidation(errors);
    if (errors.fullname === '' && errors.email === '' && errors.number === '' && errors.message === '') {
      return true;
    } else {
      return false;
    }
  };
  function handleChange(e) {
    const { name, value } = e.target;
    setInputValue({ ...inputValues, [name]: value });
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    const isSuccess = checkValidation();
    if (isSuccess) {
      contactFrom();
    }
  };
  const contactFrom = () => {
    let contactFromData = new FormData();
    contactFromData.append('full_name', inputValues.fullname);
    contactFromData.append('email', inputValues.email);
    contactFromData.append('phone_number', inputValues.number);
    contactFromData.append('message', inputValues.message);
    const params = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "token": localStorage.getItem('token')
      }
    };
    axios.post(`${appConstant.API_URL}/bx_block_contact_us/contact_forms`, contactFromData, params)
      .then(res => {
        if (res.data.status === 200) {
          setResultMessage(res.data.message);
          setInputValue({
            fullname: '',
            email: '',
            number: '',
            message: ''
          })
        } else {
          setResultMessage(res.data.message);

        }
      })
      .catch(error => {
        // props.changeLogin(true);
      });
  }
  return (
    <>
      <HireHeader id="4" />
      <div className='filter-blank'></div>
      <div className='contact-us'>
        <div className='background-color'>
          <h4 data-testid="contact-us">Contact Us</h4>
          <p>Have any questions or comments? Feel free to drop us a message!</p>
        </div>
        <div className='content'>
          <div className='row'>
            <div className='col-xl-4 col-lg-5 col-md-6'>
              <h6>Contact Information</h6>
              <p testID="help-desc">Complete the form, and our team will respond within 24 hours.</p>
              <table>
                <tbody>
                  <tr className='align-top'>
                    <td className='text-center pb-3'><img src="/images/mobile-icon.png" alt="mobile" /></td>
                    <td className='ps-3 pb-3'><p className='m-0'><a href={"tel:" + contactUs?.attributes?.phone_number}>{contactUs?.attributes?.phone_number}</a></p></td>
                  </tr>
                  <tr className='align-top'>
                    <td className='text-center pb-3'><img src="/images/email-icon.png" alt="email" /></td>
                    <td className='ps-3 pb-3'><p className='m-0'><a href={"mailto:" + contactUs?.attributes?.email}>{contactUs?.attributes?.email}</a></p></td>
                  </tr>
                  <tr className='align-top'>
                    <td className='text-center pb-3'><img src="/images/location.png" alt="address" /></td>
                    <td className='ps-3 pb-3'><p className='m-0'>{contactUs?.attributes?.address}</p></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className='col-xl-8 col-lg-7 col-md-6'>
              <div className='contact-form'>
                <form className='alyya-form'>
                  {resultMessage && (typeof resultMessage === "string") && (resultMessage.split(' ')[0] === 'Thanks') && <p className='formErrors'>{resultMessage}</p>}
                  <input className={errorBorderFullName ? 'errorBorderFullName' : 'style-input'} type="text" name='fullname' value={inputValues.fullname} placeholder='Full Name' onChange={(e) => handleChange(e)} />
                  {resultMessage && Object.keys(resultMessage).filter((items) => {
                    return (resultMessage[items].split(' ')[0] === 'Full')
                  }).map((item, index) => {
                    return resultMessage && <p className='formErrors p-2'>{resultMessage[item]}</p>
                  })}
                  {validation.fullname && <p className='formErrors' style={{ display: errorBorderFullName ? 'block' : 'none' }}>{validation.fullname}</p>}
                  <input className={errBorder ? 'errorBorder' : 'style-input'} type="text" name='number' value={inputValues.number} placeholder='Phone number' onChange={(e) => handleChange(e)} data-testid="contact-number" />
                  {resultMessage && Object.keys(resultMessage).filter((items) => {
                    return (resultMessage[items].split(' ')[0] === 'Phone')
                  }).map((item, index) => {
                    return resultMessage && <p className='formErrors p-2'>{resultMessage[item]}</p>
                  })}
                  {validation.number && <p className='formErrors' style={{ display: errBorder ? 'block' : 'none' }} data-testid="contact-number-err">{validation.number}</p>}
                  <input className={errBorderEmail ? 'errorBorderEmail' : 'style-input'} type="text" name='email' value={inputValues.email} placeholder='Email ID' onChange={(e) => handleChange(e)} />
                  {resultMessage && Object.keys(resultMessage).filter((items) => {
                    return (resultMessage[items].split(' ')[0] === 'Email')
                  }).map((item, index) => {
                    return resultMessage && <p className='formErrors p-2'>{resultMessage[item]}</p>
                  })}
                  {validation.email && <p className='formErrors' style={{ display: errBorderEmail ? 'block' : 'none' }}>{validation.email}</p>}
                  <textarea className={errBorderText ? 'errorBorderText' : 'style-input'} rows="5" name="message" value={inputValues.message} placeholder='Write your message' onChange={(e) => handleChange(e)}></textarea>
                  {resultMessage && Object.keys(resultMessage).filter((items) => {
                    return (resultMessage[items].split(' ')[0] === 'Message')
                  }).map((item, index) => {
                    return resultMessage && <p className='formErrors p-2'>{resultMessage[item]}</p>
                  })}
                  {validation.message && <p className='formErrors' style={{ display: errBorderText ? 'block' : 'none' }}>{validation.message}</p>}
                  <div className='text-end'>
                    <button data-testid="submit-button" type="button" className='submit-buttons green' onClick={handleSubmit}>Send your message</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!showFooter && <HireFooter />}
    </>
  )
};

export default ContactUs;
