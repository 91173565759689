import React from 'react';
import HireHeader from './HireHeader';
import HireFooter from './HireFooter';
import {Link} from 'react-router-dom';

function Message() {
    const handleChange = (e) => {
        
    }
  return (
    <>
        <HireHeader/>
        <div className='filter-blank'></div>
        <div className='cart home-content'>
            <div className='container'>
                <div className='breadcrumbs'>
                    <div><Link to="/home/hire-equipment"> Home /</Link> <strong>Message</strong></div>
                </div>
                
            </div>
        </div>
        <HireFooter/>
    </>
    )
};

export default Message;