import appConstant from '../../shared/constant/constant.json'
import { handlePresenceUpdate } from '../utils/presenceUpdate';

class WebSocketService {
    constructor() {
        this.socket = null;
    }

    connect(onMessageReceived,updateOnlineStatus) {
        const authId = localStorage.getItem("unique_auth_id"); 
        if (!authId) {
            console.error('No unique_auth_id found in localStorage');
            return;
        }

        if (this.socket && this.socket.readyState === WebSocket.OPEN) {
            return;
        }

        this.socket = new WebSocket(`${appConstant.WEBSOCKET_URL}`);

        this.socket.onopen = () => {
            this.subscribeToChat(authId);
        };

        this.socket.onmessage = (event) => {
            const messageData = JSON.parse(event.data);
            if (messageData.type === 'ping') return;

            if(onMessageReceived){
                onMessageReceived(messageData)
            }
            if (messageData.message && messageData.message.event && messageData.message.event.event === "presence.update") {
                const userData = messageData.message.data;
                
                handlePresenceUpdate(userData, authId, updateOnlineStatus);
              }
        };

        this.socket.onclose = () => {
        
        };

        this.socket.onerror = (error) => {
            console.error('WebSocket error');
        };
    }

    subscribeToChat(authId) {
        const subscriptionMessage = {
            command: 'subscribe',
            identifier: JSON.stringify({
                channel: 'ChatChannel',
                unique_auth_id: authId,
            }),
        };

        this.socket.send(JSON.stringify(subscriptionMessage));
    }

    sendMessage(message) {
        if (this.socket && this.socket.readyState === WebSocket.OPEN) {
            this.socket.send(JSON.stringify(message));
        } else {
            console.error('WebSocket is not open');
        }
    }

    disconnect() {
        if (this.socket) {
            this.socket.close();
        }
    }
}

const websocketService = new WebSocketService();
export default websocketService;

