import React from 'react';
import Header from './Header';
import HireFooter from '../hireEquipment/HireFooter';
import Sidebar from './Sidebar';
import ContactUs from '../hireEquipment/ContactUs';

function Help() {
  const showFooter=true
  return (
    <>
    <Header/>
    <div className='blank'></div>
      <div className='container py-4'>
        <div className='row'>
          <div className='col-md-3'>
            <Sidebar id="1"/>
          </div>
          <div className='col-md-9'>
            <div className='rightSide'>

            <div className='breadcrumbs'>
                  <span className='active-page ContactUsNew'> <ContactUs showFooter={showFooter} /></span>
              </div>
           
            </div>
          </div>
        </div>
      </div>
      <HireFooter />
    </>
  )
};

export default Help;
