import React, {useEffect,useState} from 'react';
import HireHeader from './HireHeader';
import HireFooter from './HireFooter';
import {Link, useNavigate, useParams} from 'react-router-dom';
import ReactStars from "react-rating-stars-component";
import axios from 'axios';
import appConstant from '../shared/constant/constant.json';
import Moment from 'moment';
import LoadingSpinner from '../shared/Loader';

function CurrentOrders() {
   
    const nav = useNavigate();
    const modalRating = {
        size: 15,
        count: 5,
        color: "#757575",
        activeColor: "#f8b84e",
        value: 3,
        a11y: true,
        isHalf: false,
        emptyIcon: <i className="fa fa-star" />,
        halfIcon: <i className="fa fa-star-half-alt" />,
        filledIcon: <i className="fa fa-star" />,
        onChange: rateValue => {
            console.log(`Example 2: rate value is ${rateValue}`);
        }
    }
    const props = useParams();
    useEffect(() => {
        userCurrentOrder( 1);
    }, []);
    const [isLoading, setIsLoading] = useState(true);
    const [currentOrdersDetails, setCurrentOrdersDetails] = React.useState({});
    const [zones, setZones] = React.useState()
    const [paginationPages, setPaginationPages] = React.useState([]);
    const params = {
        headers: {
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": '*',
            "token": localStorage.getItem('token')
        }
    };
    const userCurrentOrder = (page) => {
        setIsLoading(true);
        axios.get(`${appConstant.API_URL}/bx_block_order_management/orders/${props.orderId}/order_quotes?page=${page}&limit=5`, params)
            .then(res => {
                setIsLoading(false);
                if (res.data.status === 200) {
                    setCurrentOrdersDetails(res.data);
                    const zoneName = res.data.order_data.attributes.order_dtl.attributes.zone        
                    setZones(zoneName);
                    const row = [];
                    for (var i = 1; i <= parseInt(res.data.total_pages); i++) {
                        row.push(i);
                    }
                    setPaginationPages(row);
                }
                else{
                    setCurrentOrdersDetails(res.data);
                }
                
            })
            .catch(error => {
                setIsLoading(false);
            });
    }
    const approveQuote = (orderId, quoteId, page = 1) => {
        const approveQuoteData = {
            order_id: orderId,
            quote_id: quoteId
        }
        axios.post(`${appConstant.API_URL}/bx_block_order_management/orders/approved_quote`, approveQuoteData, params)
            .then(res => {
                if (res.data.status === 200) {
                    // userCurrentOrder( page);
                    nav('/home/orders/previous');
                }else{
                    userCurrentOrder( page);
                }
            })
            .catch(error => {
                // props.changeLogin(true);
            });
    }
    const declineQuote = (orderId, quoteId, page = 1) => {
        const declineQuoteData = {}
        axios.put(`${appConstant.API_URL}/bx_block_order_management/orders/declined_quote?order_id=${orderId}&quote_id=${quoteId}`, declineQuoteData, params)
            .then(res => {
                if (res.data.status === 200) {
                    userCurrentOrder( page);
                }else{
                    userCurrentOrder( page);
                }
            })
            .catch(error => {
                // props.changeLogin(true);
            });
    }
    return (
        <>
            <HireHeader/>
            <div className='filter-blank'></div>
            <div className='orders home-content'>
                <div className='container'>
                    <div className='d-lg-flex justify-content-between align-items-center'>
                        <div className='title'><Link to="/home/orders">Orders /</Link> <span>Current Orders</span></div>
                        <div className='title text-start'>
                            <button className='order-btn-group m-0'><img className='icons' src="/images/print.png" alt="Print" /> Print</button>
                        </div>
                    </div>
                    <div className="table-responsive alyya-table">
                        <table className="table table-borderless">
                            <thead>
                            <tr>
                                <th className='min-300'>Equipment</th>
                                <th className='min-210'>Type / Capacity</th>
                                <th className='min-210'>Duration</th>
                                <th>Quantity</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td className='min-300'>
                                    <div className='d-flex align-items-center'>
                                        <img src={currentOrdersDetails?.order_data?.attributes?.order_dtl?.attributes && currentOrdersDetails?.order_data?.attributes?.order_dtl?.attributes?.image} className="order-image" alt="equipment" />
                                        <div>
                                            <strong className='d-block'>{currentOrdersDetails?.order_data?.attributes?.order_dtl?.attributes?.equipment_name}</strong>
                                            <span className='d-block mt-2'>{currentOrdersDetails?.order_data?.attributes?.order_dtl?.attributes?.model}</span >
                                        </div>
                                    </div>
                                </td>
                                <td className='min-210'>
                                    <span className='d-block mt-2'>{currentOrdersDetails?.order_data?.attributes?.order_dtl?.attributes?.sub_category_name}</span>
                                    <span className='d-block mt-2'>{currentOrdersDetails?.order_data?.attributes?.order_dtl?.attributes?.capacity}</span >
                                </td>
                                <td className='text-start  min-300 px-2' style={{width : '230px'}}>
                                    <div className='row m-0 p-0'>
                                        <div className='col-6 px-0'><div className='mb-2'>Start Date</div></div>
                                        <div className='col-6 px-0'><strong>{Moment(currentOrdersDetails?.order_data?.attributes?.order_dtl?.attributes?.start_date).format('DD-MM-YYYY')}</strong></div>
                                        <div className='col-6 px-0'><div className='mb-2'>End Date</div></div>
                                        <div className='col-6 px-0'><strong>{Moment(currentOrdersDetails?.order_data?.attributes?.order_dtl?.attributes?.end_date).format('DD-MM-YYYY')}</strong></div>
                                        <div className='col-6 px-0'><div className='mb-2' data-testid="zone-location">Zone </div></div>
                                        <div className='col-6 px-0' ><strong>{zones}</strong></div>
                                    </div>
                                </td>
                                <td>
                                    <strong>{currentOrdersDetails?.order_data?.attributes?.order_dtl?.attributes?.quantity}</strong>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    {isLoading ? <LoadingSpinner/> :
                        <>
                        
                            <div className="table-responsive alyya-table alyya-table-borderless mb-5">
                            <div style={{maxHeight:"350px",overflowY:"scroll"}}>
                                <table className="table ">
                                    <tbody>
                                    {currentOrdersDetails?.quotes?.length > 0 && currentOrdersDetails?.quotes?.map((result, i) =>{
                                        return (
                                        <>
                                            <tr className="border-0" key={i}>
                                           
                                                <td className='min-300 pb-0 border-0'>
                                                    <div className='d-flex align-items-center'>
                                                        

                                                        <img src={result?.attributes?.vendor?.image ? result?.attributes?.vendor?.image : "/images/userr.png"} className="order-image-2"
                                                             alt="equipment"/>
                                                        <div>
                                                            <strong
                                                                className='d-block mb-2'>{result?.attributes?.vendor?.full_name}</strong>
                                                            <div
                                                                className='d-flex justify-content-between align-items-center'>
                                                                {/* <ReactStars {...modalRating} /> */}
                                                                <ReactStars count={5} color={'#757575'} value={Math.round(result?.attributes?.vendor?.vendor_rating)} a11y={true} isHalf={false} edit={false} emptyIcon= {<i className="fa fa-star" />} halfIcon= {<i className="fa fa-star-half-alt" />} filledIcon={<i className="fa fa-star" />} />
                                                                {/*<span className='rating ml-3'>(12 reviews)</span>*/}
                                                                
                                                            </div>
                                                        
                                                        </div>
                                                        
                                                    </div>
                                                </td>
                                                <td className='text-start  min-300 px-2 pb-0 border-0' style={{width : '400px'}}>
                                                    <div className='row m-0 p-0'>
                                                        <div className='col-6 px-0'><div className='mb-2'>Mobilisation provided?</div></div>
                                                        <div className='col-6 px-0'><strong>{(result?.attributes?.mobilisation === true) && <strong> Yes</strong>}
                                                            {(result?.attributes?.mobilisation === false) && <strong> No</strong>}</strong></div>
                                                        <div className='col-6 px-0'><div className='mb-2' data-testid="zoneLocation">Zone/ Location</div></div>
                                                        <div className='col-6 px-0'><strong>{result?.attributes?.zone ?result.attributes.zone:zones}</strong></div>
                                                        <div className='col-6 px-0'><div className='mb-2'>Start Date</div></div>
                                                        <div className='col-6 px-0'><strong>{Moment(result?.attributes?.start_date).format('DD-MM-YYYY')}</strong></div>
                                                    </div>
                                                </td>
                                                <td className='text-start  min-300 px-2 pb-0 border-0' style={{width : '300px'}}>
                                                    <div className='row m-0 p-0'>
                                                        <div className='col-6 px-0'><div className='mb-2'>Quantity</div></div>
                                                        <div className='col-6 px-0'><strong>{result?.attributes?.quantity}</strong></div>
                                                        <div className='col-6 px-0'><div className='mb-2'>Cost</div></div>
                                                        <div className='col-6 px-0'><strong><div className='d-inline-block me-2'>SAR</div>
                                                            {result?.attributes?.total ?  Number(result?.attributes?.total).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2, }).replace(/^(\D+)/, '') : 0}
                                                            </strong></div>
                                                        <div className='col-6 px-0'><div className='mb-2'>End Date</div></div>
                                                        <div className='col-6 px-0'><strong>{Moment(result?.attributes?.end_date).format('DD-MM-YYYY')}</strong></div>
                                                    </div>
                                                </td>
                                                {!result.attributes.equipment_available && 
                                                
                                                <td className="pb-0 border-0">
                                                    
                                                    {result?.attributes?.available === true && <>
                                                        {result?.attributes?.status === 'sent'  &&
                                                            <>
                                                            
                                                                <button className='table-success-button mb-2 d-block' type="button"
                                                                        onClick={() => {
                                                                            approveQuote(currentOrdersDetails?.order_data?.id, result?.id, currentOrdersDetails?.current_page)
                                                                        }}>Approve</button>
                                                                <button className='table-red-button declined-button d-block' type="button"
                                                                        onClick={() => {
                                                                            declineQuote(currentOrdersDetails?.order_data?.id, result?.id, currentOrdersDetails?.current_page)
                                                                        }}>Decline</button>
                                                            </>}
                                                        {result?.attributes?.status === 'expired' &&
                                                            <button className='table-red-button' type="button">Expired</button>}
                                                        {result?.attributes?.status === 'declined' &&
                                                            <button className='table-red-button' type="button">Declined</button>
                                                            }
                                                        {result?.attributes?.status === 'approved' &&
                                                            <button className='table-red-button' type="button">Approved</button>}
                                                    </> 
                                                    }
                                                    {result?.attributes?.available === false && <button className='table-red-button' type="button">Not Available</button>}
                                                </td>
                                                }
                                                
                                            </tr>

                                            <tr>                        
                                                <td className="text-start pt-1 border-0" colSpan="2"> <div className="d-flex w-100"><div className="d-flex" style={{width: '70px'}}></div> <div className="w-100" data-testid="desc-title">Delivery of equipment based on subject to availability</div></div></td>
                                                {result?.attributes.equipment_available &&
                                                <td className="text-start pt-1 border-0 ps-2" colSpan="2"> <div className="d-flex w-100"><div className="w-100 not-available-heading" data-testid="message-eq">{result.attributes.equipment_available}</div></div></td>
                                               }
                                            </tr>
                                       
                                        
                                        
                                          
  
                                        </>
                                        )})} 
                                    {currentOrdersDetails?.quotes?.length === 0 && <tr>
                                        <td colSpan='4'><strong><div >You don't have any new orders available.</div> 
                                        </strong></td>
                                    </tr>}
                                    </tbody>
                                </table>
                                </div>
                            </div>
                            {currentOrdersDetails?.quotes?.length > 0 &&
                                <ul className="pagination justify-content-between alyya-pagination">
                                    {currentOrdersDetails?.current_page === 1 && <li className="page-item disabled"><a className="page-link">&#129120; &nbsp; Previous</a></li>}
                                    {currentOrdersDetails?.current_page !== 1 && <li className="page-item" onClick={() => {userCurrentOrder(currentOrdersDetails?.current_page-1)}}><a className="page-link">&#129120; &nbsp; Previous</a></li>}
                                    <div className='d-flex'>
                                        {paginationPages?.length > 0 && paginationPages?.map((result, i) =>
                                            <>
                                                {result === currentOrdersDetails?.current_page &&  <li className="page-item"><a className="page-link active">{result}</a></li>}
                                                {result !== currentOrdersDetails?.current_page &&  <li className="page-item"><a className="page-link" onClick={() => {userCurrentOrder(result)}}>{result}</a></li>}

                                            </>
                                        )}
                                    </div>

                                    {currentOrdersDetails?.current_page === currentOrdersDetails?.total_pages && <li className="page-item disabled"><a className="page-link">Next &nbsp; &#129122;</a></li>}
                                    {currentOrdersDetails?.current_page !== currentOrdersDetails?.total_pages && <li className="page-item" onClick={() => {userCurrentOrder(currentOrdersDetails?.current_page+1)}}><a className="page-link">Next &nbsp; &#129122;</a></li>}
                                </ul>
                            }
                        </>
                    }
                </div>
            </div>
            <HireFooter/>
        </>
    )
};

export default CurrentOrders;