import React from "react"


function MessagesItem({username, usermessage, isActive, isOnline, img, isRead, createdAt}) {
    const firstLetter = username ? username.charAt(0).toUpperCase() : "?";
    const color = "#ff8d27"
    return (
        <>
            <div className="messagesItemContainer" data-testid="message-item">
                <div className={`${isOnline ? 'dashboardChatAvatar' : ''}`}>
                {img ? (
        <img
          src={img}
          alt={username}
          className="dashBoardImg"
        />
      ) : (
        <div
          style={{
            width: 40,
            height: 40,
            borderRadius: '50%',
            backgroundColor: color,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'white',
            fontSize: 24,
          }}
        >
          {firstLetter}
        </div>
      )}
                </div>
                <div className="messagesItemContent">
                    <div className="infoSection">
                        <p className="messagesUserName">
                            {username}
                        </p>
                        <div className={`messageTime ${isActive}`}>
                            <p className="timeStatus">
                                {createdAt}
                            </p>
                            
                        </div>
                    </div>
                        <div className="messageStatus">
                            <p className={`messagePreview ${isActive}`}>
                                {usermessage}
                            </p>
                            <div className={`${isRead ? 'readStatus' : ''}`}></div>
                        </div>
                </div>
            </div>
        </>
    )

}

export default MessagesItem