  import React from "react";
  export const  getFileNameFromUrl = (url)=>{
    let newUrl=url.split("/")
    let fileName=newUrl[newUrl.length-1]
    const myFile = new File([], fileName, {
      type: 'application/pdf',
      name:fileName
   });
   
    return myFile

}

export const YearsOfManufactures = (defaultYear = '') => {
  const currentYear = (new Date()).getFullYear();
  const row = [];
  for (var i = 2000; i <= parseInt(currentYear); i++) {
      
      if(defaultYear && parseInt(defaultYear) === i){
        row.push(<option value={i} key={i} selected='selected'>{i}</option> );
    }else{
        row.push(<option value={i} key={i}>{i}</option> );
    }
  }
  return row.sort((a,b)=>b.key-a.key);
}

