import axios from "axios";
import appConstant from '../constant/constant.json';

const baseUrl = appConstant.API_URL;

const instance = axios.create({
    baseURL: `${baseUrl}`,
    headers: {
        "Content-Type": 'application/json',
        "Access-Control-Allow-Origin": "*"
    }
});


instance.interceptors.request.use((config) => {
    const token = localStorage.getItem('refresh_token');
    if (token) {
        config.headers['token'] = token; 
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

export default instance;