import React, {useState, useEffect} from 'react';
import HireHeader from './HireHeader';
import HireFooter from './HireFooter';
import LoadingSpinner from '../shared/Loader';
import axios from 'axios';
import appConstant from '../shared/constant/constant.json';
import { Link, useNavigate  } from "react-router-dom";

function ListEquipment() {

  const [isLoading, setIsLoading] = useState(true);
  const nav = useNavigate();

  useEffect(() => {
    if (localStorage.getItem('token')) {
      if (localStorage.getItem('registration') === "true" && localStorage.getItem('registrationStatus') === "in_review") {
        nav('/dashboard');
      } else {
        nav('/register-organization');
      }
    }
  }, []);

  return (
    <>
    <HireHeader/>
    <div className='filter-blank'>
    </div>
    <HireFooter/>
    </>
  )
};

export default ListEquipment;