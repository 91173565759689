import React, {useState, useEffect,useRef} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import Header from '../Header';
import Sidebar from '../Sidebar';
import axios from 'axios';
import {getFileNameFromUrl} from "../../utils"
import appConstant from '../../shared/constant/constant.json';
import {YearsOfManufactures} from "../../utils"
import "../../admin/css/registration.css"


function EditEquipment() {

    const formatCurrency = (amount) => {
        return parseFloat(amount).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }).replace(/^(\D+)/, '');
      };

    useEffect(()=>{
        getCategories();
        equipmentData();
        filterList();
    }, []);
    const props = useParams();
    const nav = useNavigate();
    const inputRefs = useRef({})
    const [categories, setCategories] = React.useState([]);
    const [capacity, setCapacity] = React.useState([]);
    const [showImages, setShowImages] = React.useState(1);
    const [selectedCategoryId, setSelectedCategoryId] = React.useState(0);
    const [selectedCapacityId, setSelectedCapacityId] = React.useState(0);
    const [selectedLocationId, setSelectedLocationId] = React.useState(0);
    const [makers, setMakers] = React.useState([]);
    const [models, setModels] = React.useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const showFiles = () => {
        setShowImages(parseInt(showImages)+1);
    }
    const [equipments, setEquipments] = React.useState({});
    const [locations, setLocations] = React.useState({});
    const params = {
        headers: {
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": '*',
            "token": localStorage.getItem('token')
        }
    };
    const getCategories = () => {
        axios.get(`${appConstant.API_URL}/bx_block_categories/categories`, params)
            .then(res => {
                if (res?.data?.data?.length > 0) {
                    setCategories(res.data.data);
                    setCapacity(res.data.data.attributes.sub_categories_name[0]);
                 }
            })
            .catch(error => {
            });
    }
    const getLocations = () => {
        axios.get(`${appConstant.API_URL}/bx_block_categories/fetch_locations`, params)
            .then(res => {
                if (res.data.status === 200) {
                    setLocations(res.data.locations);
                }else {
                }
            })
            .catch(error => {
            });
    }
    const getSubLocations = () => {
        axios.get(`${appConstant.API_URL}/bx_block_categories/fetch_locations`, params)
            .then(res => {
                if (res.data.status === 200) {
                    setLocations(res.data.locations);
                }else {
                }
            })
            .catch(error => {
            });
    }
    const getCapacity = (subCategoryId, selectedCapacityId) => {
        axios.get(`${appConstant.API_URL}/bx_block_categories/capacities_of_sub_category?sub_category_id=${subCategoryId}`, params)
            .then(res => {
                if (res.data.status === 200) {
                    setCapacity(res.data.capacities);
                    filterList(subCategoryId);
                }else {
                }
            })
            .catch(error => {
            });
    }
    const [inputValues, setInputValue] = useState({
        product_name: "",
        category: "",
        product_model: "",
        year : "",
        capacity : "",
        product_maker: "",
        base_location: "",
        work_hours: "",
        video_url: "",
        description : "",
        price_per_day : "",
        price_per_week : "",
        price_per_month : "",
    });

    const [validation, setValidation] = useState({
        product_name: "",
        category: "",
        product_model: "",
        year : "",
        capacity : "",
        product_maker: "",
        base_location: "",
        work_hours: "",
        video_url: "",
        description : "",
        registration : "",
        insurance : "",
        TOV : "",
        operaterId : "",
        operaterCertificate : "",
        front : "",
        back : "",
        side1 : "",
        side2 : "",
        price_per_day : "",
        price_per_week : "",
        price_per_month : ""
    });
    const [fileValidation, setFileValidation] = useState({
        registration: '',
        insurance: '',
        TOV: '',
        operaterId: '',
        operaterCertificate: '',
        front: ''
    });

    const [checked, setChecked] = useState(false);

    const [registration, setRegistration] = useState({name: ''});
    const [insurance, setInsurance] = useState({name: ''});
    const [TOV, setTOV] = useState({name: ''});
    const [operaterId, setOperaterId] = useState({name: ''});
    const [operaterCertificate, setOperaterCertificate] = useState({name: ''});
    const [front, setFront] = useState();
    const [back, setBack] = useState();
    const [side1, setSide1] = useState();
    const [side2, setSide2] = useState();
    const [side3, setSide3] = useState();
    const [side4, setSide4] = useState();
    const [resultMessage, setResultMessage] = useState('');

    function handlePriceChange(e) {

        const {name, value, selectionStart, selectionEnd} = e.target
        const numericValue = value.replace(/[^0-9.-]+/g, '');
      
        setInputValue((prevCurrencies) => ({
          ...prevCurrencies,
          [name]: numericValue,
        }));
      
        if (inputRefs.current[name]) {
          requestAnimationFrame(() => {
            const input = inputRefs.current[name];
            const cursorOffset = value.length - input.value.length;
            const newCursorPosition = selectionStart - cursorOffset;
            input.setSelectionRange(newCursorPosition, newCursorPosition);
          });
        }
}



    function handleChange(e){
        const {name,value} = e.target
        setInputValue({...inputValues,[name]:value})

    }
    function fetchCapacity(e) {
        const { name, value } = e.target;
        setInputValue({ ...inputValues, [name]: value });
        getCapacity(value);
        getSubLocations(value)
    }
    const uploadRegistration = event => {
        const fileData = event.target.files[0];
        if(fileData !== undefined) {
            setRegistration(fileData);
        }
    }
    const uploadInsurance = event => {
        const fileData = event.target.files[0];
        if(fileData !== undefined) {
            setInsurance(fileData);
        }
    }
    const uploadTOV = event => {
        const fileData = event.target.files[0];
        if(fileData !== undefined) {
            setTOV(fileData);
        }
    }
    const uploadOperaterId = event => {
        const fileData = event.target.files[0];
        if(fileData !== undefined) {
            setOperaterId(fileData);
        }
    }
    const uploadOperaterCertificate = event => {
        const fileData = event.target.files[0];
        if(fileData !== undefined) {
            setOperaterCertificate(fileData);
        }
    }
    const uploadFront = event => {
        const fileData = event.target.files[0];
        if(fileData !== undefined) {
            setFront(fileData);
        }
    };
    const uploadBack = event => {
        const fileData = event.target.files[0];
        if(fileData !== undefined) {
            setBack(fileData);
        }
    };
    const uploadSide1 = event => {
        const fileData = event.target.files[0];
        if(fileData !== undefined) {
            setSide1(fileData);
        }
    };
    const uploadSide2 = event => {
        const fileData = event.target.files[0];
        if(fileData !== undefined) {
            setSide2(fileData);
        }
    };
    const uploadSide3 = event => {
        const fileData = event.target.files[0];
        if(fileData !== undefined) {
            setSide3(fileData);
        }
    };
    const uploadSide4 = event => {
        const fileData = event.target.files[0];
        if(fileData !== undefined) {
            setSide4(fileData);
        }
    };

    const checkValidation = () => {
        let errors = JSON.parse(JSON.stringify(validation));
        //product_name validation
        if (!inputValues.product_name.trim()) {
            errors.product_name = "Product name is required";
        } else {
            errors.product_name = "";
        }
        //category validation
        if (!inputValues.category) {
            errors.category = "Category is required";
        } else {
            errors.category = "";
        }
        //product_model validation
        //year validation
        if (!inputValues.year) {
            errors.year = "Year is required";
        } else {
            errors.year = "";
        }
        //capacity validation
        if (!inputValues.capacity) {
            errors.capacity = "Capacity is required";
        } else {
            errors.capacity = "";
        }
        //product_maker validation
        if (!inputValues.product_maker) {
            errors.product_maker = "Product Maker is required";
        } else {
            errors.product_maker = "";
        }
        //base_location validation
        if (!inputValues.base_location) {
            errors.base_location = "Base Location is required";
        } else {
            errors.base_location = "";
        }
        //work_hours validation
        if (!inputValues.work_hours) {
            errors.work_hours = "Work Hours is required";
        } else {
            errors.work_hours = "";
        }
        //video_url validation
        
        //description validation
        if (!inputValues.description.trim()) {
            errors.description = "Description is required";
        } else {
            errors.description = "";
        }
        // terms checked errors
        if (!checked) {
            errors.checkedError = 'Please select';
        } else {
            errors.checkedError = '';
        }
        //registration validation
        if (!registration.name && fileValidation.registration === '') {
            errors.registration = 'Please upload document';
        } else {
            errors.registration = "";
        }
        //insurance validation
        if (!insurance.name && fileValidation.insurance === '') {
            errors.insurance = "Please upload document";
        } else {
            errors.insurance = "";
        }
        //TOV validation
        if (!TOV.name && fileValidation.TOV === '') {
            errors.TOV = "Please upload document";
        } else {
            errors.TOV = "";
        }
        if (!operaterId.name && fileValidation.operaterId === '') {
            errors.operaterId = "Please upload document";
        } else {
            errors.operaterId = "";
        }
        //operaterCertificate validation
        if (!operaterCertificate.name && fileValidation.operaterCertificate === '') {
            errors.operaterCertificate = "Please upload document";
        } else {
            errors.operaterCertificate = "";
        }
        //front validation
        if (!front  && fileValidation.front === '') {
            errors.front = "Please upload image";
        } else {
            errors.front = "";
        }
        //back validation
        

        //price_per_day validation
        const numberCond = /^\d+(\.\d{1,2})?$/;

        if (!inputValues.price_per_day) {
            errors.price_per_day = "Required";
        } else if (!inputValues.price_per_day.match(numberCond)) {
            errors.price_per_day = "Please enter numeric value";
        } else if (inputValues.price_per_day < 1) {
            errors.price_per_day = "price should be greater than 0.";
        } else {
            errors.price_per_day = "";
        }

        //price_per_week validation
        if (!inputValues.price_per_week) {
            errors.price_per_week = "Required";
        } else if (!inputValues.price_per_week.match(numberCond)) {
            errors.price_per_week = "Please enter numeric value";
        } else if (inputValues.price_per_week < 1) {
            errors.price_per_week = "price should be greater than 0.";
        } else {
            errors.price_per_week = "";
        }

        //price_per_month validation
        if (!inputValues.price_per_month) {
            errors.price_per_month = "Required";
        } else if (!inputValues.price_per_month.match(numberCond)) {
            errors.price_per_month = "Please enter numeric value";
        } else if (inputValues.price_per_month < 1) {
            errors.price_per_month = "price should be greater than 0.";
        } else {
            errors.price_per_month = "";
        }

        setValidation(errors);
            if (errors.product_name === '' && errors.category === '' && errors.product_model === '' && errors.year === '' && errors.capacity === '' && errors.product_maker === '' && errors.base_location === '' && errors.work_hours === '' && errors.description === '' && errors.registration === '' && errors.insurance === '' && errors.TOV === '' && errors.operaterId === '' && errors.operaterCertificate === '' && errors.front === '' && errors.price_per_day === '' && errors.price_per_week === ''&& errors.price_per_month === ''){
        return true;
        } else {
            return false;
        }
    };

    const checkDraftValidation = () => {
        let errors = JSON.parse(JSON.stringify(validation));

        //product_name validation
        if (!inputValues.product_name.trim()) {
            errors.product_name = "Product Name is required";
        } else {
            errors.product_name = "";
        }
        if (!inputValues.category) {
            errors.category = "Category is required";
        } else {
            errors.category = "";
        }
        if (!front && fileValidation.front === '') {
            errors.front = "Please upload image";
        } else {
            errors.front = "";
        }
        setValidation(errors);
        if (errors.product_name === '' && errors.category === ''&& errors.front === ''){
            return true;
        } else {
            return false;
        }
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        const isSuccess = checkValidation();
        if (isSuccess){
            editEquipment('in_review');
        }
    };
    const [modelData, setModelData] = useState(false);
    const editEquipment = (status) => {
        const isDraftValidation = checkDraftValidation();
        if(isDraftValidation) {
            setIsLoading(true);
            let equipmentData = new FormData();
            if (inputValues.product_name) {
                equipmentData.append('data[product_name]', inputValues.product_name.trim());
            }
            if (inputValues.category) {
                equipmentData.append('data[sub_category_id]', inputValues.category);
            }
            if (inputValues.product_model) {
                equipmentData.append('data[model_id]', inputValues.product_model);
            }
            if (inputValues.product_maker) {
                equipmentData.append('data[maker_id]', inputValues.product_maker);
            }
            if (inputValues.capacity) {
                equipmentData.append('data[capacity_id]', inputValues.capacity);
            }
            if (inputValues.base_location) {
                equipmentData.append('data[base_location_id]', inputValues.base_location);
            }
            if (inputValues.description) {
                equipmentData.append('data[description]', inputValues.description.trim());
            }
            if (inputValues.video_url) {
                equipmentData.append('data[video_url]', inputValues.video_url.trim());
            }
            if (inputValues.year) {
                equipmentData.append('data[year_of_manufature]', inputValues.year.trim());
            }
            if (inputValues.work_hours) {
                equipmentData.append('data[no_of_working_hours_completed]', inputValues.work_hours.trim());

            }
            if (inputValues.price_per_day) {
                equipmentData.append('data[price_per_day]', inputValues.price_per_day);
            }
            if (inputValues.price_per_week) {
                equipmentData.append('data[price_per_week]', inputValues.price_per_week);
            }
            if (inputValues.price_per_month) {
                equipmentData.append('data[price_per_month]', inputValues.price_per_month);
            }
            if (operaterId.name) {
                equipmentData.append('data[operator_file]', operaterId);
            }
            if (front) {
                equipmentData.append('data[front_image]', front);
            }
            if (back) {
                equipmentData.append('data[back_image]', back);
            }
            if (side1) {
                equipmentData.append('data[side_image1]', side1);
            }
            if (side2) {
                equipmentData.append('data[side_image2]', side2);
            }
            if (side3) {
                equipmentData.append('data[side_image3]', side3);
            }
            if (side4) {
                equipmentData.append('data[side_image4]', side4);
            }
            if (insurance.name) {
                equipmentData.append('data[upload_insurance]', insurance);
            }
            if (TOV.name) {
                equipmentData.append('data[upload_tov_certificate]', TOV);
            }
            if (registration.name) {
                equipmentData.append('data[upload_registartion]', registration);
            }
            if (operaterCertificate.name) {
                equipmentData.append('data[operator_certificate]', operaterCertificate);
            }
            equipmentData.append('data[status]', status);

            const params = {
                headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "token": localStorage.getItem('token')
                }
            };

            axios.patch(`${appConstant.API_URL}/bx_block_catalogue/equipments/${props.id}`, equipmentData, params)
                .then(res => {
                    setIsLoading(false);
                    if (res.data.status === 200) {
                        nav('/manage-equipment');
                    } else {
                        setResultMessage(res.data.message);
                    }
                })
                .catch(error => {
                    setIsLoading(false);
                    // props.changeLogin(true);
                });
        }
    }
   
    const filterList = (subCategoryId = '') => {
        axios.get(`${appConstant.API_URL}/bx_block_catalogue/equipments/filter_data`, params)
            .then(res => {
                if (res.data.status === 200) {
                    setMakers(res.data.maker);
                    if (subCategoryId && res?.data?.model[subCategoryId] !== undefined) {
                        setModelData(res?.data?.model);
                        setModels(res?.data?.model[subCategoryId])
                    }
                }else {
                }
            })
            .catch(error => {
            });
    }
    const equipmentData = () => {
        axios.get(`${appConstant.API_URL}/bx_block_catalogue/equipments/${props.id}`, params)
            .then(res => {
                if (res.data.status === 200) {
                    setEquipments(res?.data?.data);
                   
                    const data = {
                        product_name: res?.data?.data?.attributes?.product_name,
                        category: res?.data?.data?.attributes?.sub_category_id,
                        product_model: res?.data?.data?.attributes?.model_id,
                        year : res?.data?.data?.attributes?.year_of_manufature,
                        capacity : res?.data?.data?.attributes?.capacity_id,
                        product_maker: res?.data?.data?.attributes?.maker_id,
                        base_location: res?.data?.data?.attributes?.base_location_id,
                        work_hours: res?.data?.data?.attributes?.no_of_working_hours_completed,
                        video_url: res?.data?.data?.attributes?.video_url,
                        upload_insurance:res?.data?.data?.attributes?.upload_insurance,
                        upload_tov_certificate:res?.data?.data?.attributes?.upload_tov_certificate,
                        operator_file:res?.data?.data?.attributes?.operator_file,
                        operator_certificate:res?.data?.data?.attributes?.operator_certificate,

                        description : res?.data?.data?.attributes?.description,
                        price_per_day :res?.data?.data?.attributes?.price_per_day,
                        price_per_week :res?.data?.data?.attributes?.price_per_week,
                        price_per_month :res?.data?.data?.attributes?.price_per_month
                    }
                    let operaterCertificate=getFileNameFromUrl(res?.data?.data?.attributes?.operator_certificate)
                    let tovCertificate = getFileNameFromUrl(res?.data?.data?.attributes?.upload_tov_certificate)
                    let uploadInsurance = getFileNameFromUrl(res?.data?.data?.attributes?.upload_insurance)
                    let operatorFile =getFileNameFromUrl(res?.data?.data?.attributes?.operator_file)
                    let uploadRegistration =getFileNameFromUrl(res?.data?.data?.attributes?.upload_registartion)
                    setOperaterCertificate(operaterCertificate)
                    setTOV(tovCertificate)
                    setInsurance(uploadInsurance)
                    setOperaterId(operatorFile)
                    setRegistration(uploadRegistration)

                    setInputValue(data);
                    getCapacity(res?.data?.data?.attributes?.sub_category_id, res?.data?.data?.attributes?.capacity_id);
                    getSubLocations(res?.data?.data?.attributes?.sub_category_id)
                    YearsOfManufactures(res?.data?.data?.attributes?.year_of_manufature);
                    const fileData = {
                        registration: res?.data?.data?.attributes?.upload_registartion,
                        insurance: res?.data?.data?.attributes?.upload_insurance,
                        TOV: res?.data?.data?.attributes?.upload_tov_certificate,
                        operaterId: res?.data?.data?.attributes?.operator_file,
                        operaterCertificate: res?.data?.data?.attributes?.operator_certificate,
                        front: (res?.data?.data?.attributes?.front_image) ? res?.data?.data?.attributes?.front_image : ''
                    }
                    setFileValidation(fileData);
                    if(res?.data?.data?.attributes?.side_image3){
                        setShowImages(2);
                    } else if(res?.data?.data?.attributes?.side_image4) {
                        setShowImages(3);
                    }
                }else {
                }
            })
            .catch(error => {
            });
    }

    return (
        <>
            <Header/>
            <div className='blank'></div>
            <div className='container py-4' key='editEquipment'>
                <div className='row'>
                    <div className='col-md-3'>
                        <Sidebar id="3"/>
                    </div>
                    <div className='col-md-9'>
                        <div className='rightSide'>
                            <div className='breadcrumbs'>
                                <div><Link to="/manage-equipment" className='breadLink' data-testid="manage-equipment">Manage Equipment / </Link> <span className='active-page'>Edit Equipment</span></div>
                            </div>
                            <div className='content'>
                                <h3 className='title'>Edit Equipment</h3>
                                <form className='alyya-form' onSubmit={handleSubmit}>
                                    <div className='row'>
                                        <div className='col-sm-6'>
                                        <div className="did-floating-label-content">
                                            <input type="text" className='style-input did-floating-input' value={inputValues.product_name} name="product_name" id="product_name" placeholder=' ' onChange={(e) => handleChange(e)}/>
                                            <label className="did-floating-label">Product Name</label>
                                            {validation.product_name && <p className='formErrors mb-2'>{validation.product_name}</p>}
                                        </div>
                                        </div>
                                        <div className='col-sm-6'>
                                        <div className="did-floating-label-content">
                                            <select name="product_maker" className='style-input did-floating-select-bg did-floating-input' id="product_maker" value={inputValues.product_maker} onChange={(e) => handleChange(e)}>
                                                <option value='' key='category'>Select Maker</option>
                                                {makers.length > 0 && makers.map((result, i) =>
                                                    <>
                                                        <option value={result[0]} key={i}>{result[1]}</option>
                                                    </>
                                                )}
                                            </select>
                                            {inputValues.product_maker && <label class="did-floating-label">Maker</label>}
                                            {validation.product_maker && <p className='formErrors mb-2'>{validation.product_maker}</p>}
                                       </div>
                                        </div>
                                        <div className='col-sm-6'>
                                        <div className="did-floating-label-content">
                                            <select name="category" className='style-input did-floating-select-bg did-floating-input' id="category" value={!inputValues?.category ? equipments?.attributes?.sub_category_id : inputValues?.category} onChange={(e) => fetchCapacity(e)} data-testid="category-id">
                                                <option value='' key='category'>Select Category</option>
                                                {categories.length > 0 && categories.map((result, i) =>
                                                    <>
                                                        <optgroup label={result.attributes.category_name} key={i}>
                                                            {result.attributes.sub_categories_name.length > 0 && result.attributes.sub_categories_name.map((subCategory, m) =>
                                                                <><option value={subCategory[0]} key={m}>{subCategory[1]}</option>
                                                                </>
                                                            )}
                                                        </optgroup>
                                                    </>
                                                )}
                                            </select>
                                            {inputValues.category && <label class="did-floating-label">Category</label>}
                                            {validation.category && <p className='formErrors mb-2'>{validation.category}</p>}
                                       </div>
                                        </div>
                                        <div className='col-sm-6'>
                                        <div className="did-floating-label-content">
                                            <select name="capacity" className='style-input did-floating-select-bg did-floating-input' id="capacity" value={!inputValues?.capacity ? equipments?.attributes?.capacity_id : inputValues?.capacity} onChange={(e) => handleChange(e)}>
                                                <option value='' key='capacity'>Select Capacity</option>
                                                {capacity.length > 0 && capacity.map((result, i) =>
                                                    <>
                                                        <option value={result.id} key={i}>{result.value}</option>
                                                    </>
                                                )}
                                            </select>
                                            {inputValues.capacity && <label class="did-floating-label">Capacity</label>}
                                            {validation.capacity && <p className='formErrors mb-2'>{validation.capacity}</p>}
                                        </div>
                                        </div>
                                        <div className='col-sm-6'>
                                        <div className="did-floating-label-content">
                                            <select name="product_model" className='style-input did-floating-select-bg did-floating-input' id="product_model" value={inputValues.product_model} onChange={(e) => handleChange(e)}>
                                                <option value='' key='model'>Select Model</option>
                                                {models.length > 0 && models.map((result, i) =>
                                                    <>
                                                        <option value={result.id} key={i}>{result.name}</option>
                                                    </>
                                                )}
                                            </select>
                                            {inputValues.product_model && <label class="did-floating-label">Model</label>}
                                            {validation.product_model && <p className='formErrors mb-2'>{validation.product_model}</p>}
                                        </div>
                                        </div>
                                        <div className='col-sm-6'>
                                        <div className="did-floating-label-content" data-testid="year-of-manufature">
                                            <select name="year" className='style-input did-floating-select-bg did-floating-input' id="year" value={!inputValues?.year ? equipments?.attributes?.year_of_manufature : inputValues?.year} onChange={(e) => handleChange(e)}>
                                                <option value="" key="Year of manufacture">Year of manufacture</option>
                                                {YearsOfManufactures()}
                                            </select>
                                            {inputValues.year && <label class="did-floating-label">Year of manufacture</label>}
                                            {validation.year && <p className='formErrors mb-2'>{validation.year}</p>}
                                        </div>
                                        </div>

                                        <div className='col-sm-6'>
                                        <div className="did-floating-label-content">
                                            <select name="base_location" className='style-input did-floating-select-bg did-floating-input' id="base_location" value={!inputValues?.base_location ? equipments?.attributes?.base_location_id : inputValues?.base_location} onChange={(e) => handleChange(e)}>
                                                <option value='' key='location'>Select Location</option>
                                                {locations.length > 0 && locations.map((result, i) =>
                                                    <>
                                                        <option value={result.id} key={i}>{result.name}</option>
                                                    </>
                                                )}
                                            </select>
                                            {inputValues.base_location && <label class="did-floating-label">Location</label>}
                                            {validation.base_location && <p className='formErrors mb-2'>{validation.base_location}</p>}
                                        </div>
                                        </div>
                                        <div className='col-sm-6'>
                                        <div className="did-floating-label-content">
                                            <input type="text" className='style-input did-floating-input' id="work_hours" name="work_hours" value={inputValues?.work_hours} onChange={(e) => handleChange(e)} placeholder=''/>
                                            <label className="did-floating-label">No. of working hours completed</label>
                                            {validation.work_hours && <p className='formErrors mb-2'>{validation.work_hours}</p>}
                                            </div>
                                        </div>
                                        <div className='col-sm-12'>
                                        <div className="did-floating-label-content">
                                            <textarea name="description" value={inputValues?.description} onChange={(e) => handleChange(e)} className='style-input did-floating-textarea' rows="5" placeholder=' '></textarea>
                                            <label className="did-floating-label">Add description</label>
                                            {validation.description && <p className='formErrors mb-2'>{validation.description}</p>}
                                        </div>
                                        </div>
                                        <div className='col-sm-2'>
                                            <div className="box asImage" onChange={uploadFront}>
                                                <input type="file" id="front" name='front' className="inputfile" accept="image/*"/>
                                                <label htmlFor="front">
                                                    {(equipments?.attributes?.front_image && !front) && <img src={equipments?.attributes?.front_image} alt="upload"/>}
                                                    {(equipments?.attributes?.front_image && front) && <img src={URL.createObjectURL(front)} alt="upload"/>}
                                                    {(!equipments?.attributes?.front_image && front) && <img src={URL.createObjectURL(front)} alt="upload"/>}
                                                    {(!equipments?.attributes?.front_image && !front) && <img src={'/images/image-upload.png'} alt="upload"/>}
                                                    <span>Front</span></label>
                                                {validation.front && <p className='formErrors mb-2'>{validation.front}</p>}
                                            </div>
                                        </div>
                                        <div className='col-sm-2'>
                                            <div className="box asImage" onChange={uploadBack}>
                                                <input type="file" id="back" name='back' className="inputfile" accept="image/*"/>
                                                <label htmlFor="back">
                                                    {(equipments?.attributes?.back_image && !back) && <img src={equipments?.attributes?.back_image} alt="upload"/>}
                                                    {(equipments?.attributes?.back_image && back) && <img src={URL.createObjectURL(back)} alt="upload"/>}
                                                    {(!equipments?.attributes?.back_image && back) && <img src={URL.createObjectURL(back)} alt="upload"/>}
                                                    {(!equipments?.attributes?.back_image && !back) && <img src={'/images/image-upload.png'} alt="upload"/>}
                                                    <span>Back</span></label>
                                                {validation.back && <p className='formErrors mb-2'>{validation.back}</p>}
                                            </div>
                                        </div>
                                        <div className='col-sm-2'>
                                            <div className="box asImage"  onChange={uploadSide1}>
                                                <input type="file" id="side1" name='side1' className="inputfile" />
                                                <label htmlFor="side1">
                                                    {(equipments?.attributes?.side_image1 && !side1) && <img src={equipments?.attributes?.side_image1} alt="upload"/>}
                                                    {(equipments?.attributes?.side_image1 && side1) && <img src={URL.createObjectURL(side1)} alt="upload"/>}
                                                    {(!equipments?.attributes?.side_image1 && side1) && <img src={URL.createObjectURL(side1)} alt="upload"/>}
                                                    {(!equipments?.attributes?.side_image1 && !side1) && <img src={'/images/image-upload.png'} alt="upload"/>}
                                                    <span>Side 1</span></label>
                                                {validation.side1 && <p className='formErrors mb-2'>{validation.side1}</p>}
                                            </div>
                                        </div>
                                        <div className='col-sm-2'>
                                            <div className="box asImage"  onChange={uploadSide2}>
                                                <input type="file" id="side2" name='side2' className="inputfile" />
                                                <label htmlFor="side2">
                                                    {(equipments?.attributes?.side_image2 && !side2) && <img src={equipments?.attributes?.side_image2} alt="upload"/>}
                                                    {(equipments?.attributes?.side_image2 && side2) && <img src={URL.createObjectURL(side2)} alt="upload"/>}
                                                    {(!equipments?.attributes?.side_image2 && side2) && <img src={URL.createObjectURL(side2)} alt="upload"/>}
                                                    {(!equipments?.attributes?.side_image2 && !side2) && <img src={'/images/image-upload.png'} alt="upload"/>}
                                                    <span>Side 2</span></label>
                                                {validation.side2 && <p className='formErrors mb-2'>{validation.side2}</p>}
                                            </div>
                                        </div>
                                        {showImages > 1 && <div className='col-sm-2'>
                                            <div className="box asImage"  onChange={uploadSide3}>
                                                <input type="file" id="side3" name='side3' className="inputfile" accept="image/*"/>
                                                <label htmlFor="side3">
                                                    {(equipments?.attributes?.side_image3 && !side3) && <img src={equipments?.attributes?.side_image3} alt="upload"/>}
                                                    {(equipments?.attributes?.side_image3 && side3) && <img src={URL.createObjectURL(side3)} alt="upload"/>}
                                                    {(!equipments?.attributes?.side_image3 && side3) && <img src={URL.createObjectURL(side3)} alt="upload"/>}
                                                    {(!equipments?.attributes?.side_image3 && !side3) && <img src={'/images/image-upload.png'} alt="upload"/>}
                                                    <span>Side 3</span></label>
                                            </div>
                                        </div>
                                        }
                                        {showImages > 2 && <div className='col-sm-2'>
                                            <div className="box asImage"  onChange={uploadSide4}>
                                                <input type="file" id="side4" name='side4' className="inputfile" accept="image/*"/>
                                                <label htmlFor="side4">
                                                    {(equipments?.attributes?.side_image4 && !side4) && <img src={equipments?.attributes?.side_image4} alt="upload"/>}
                                                    {(equipments?.attributes?.side_image4 && side4) && <img src={URL.createObjectURL(side4)} alt="upload"/>}
                                                    {(!equipments?.attributes?.side_image4 && side4) && <img src={URL.createObjectURL(side4)} alt="upload"/>}
                                                    {(!equipments?.attributes?.side_image4 && !side4) && <img src={'/images/image-upload.png'} alt="upload"/>}
                                                    <span>Side 4</span></label>
                                            </div>
                                        </div>
                                        }
                                        {showImages < 3 &&
                                        <div className='col-sm-2 d-flex justify-content-center align-items-start'>
                                            <div className="box mt-2" onClick={() => {showFiles()}}> <span className='add-input-file'>+</span></div>
                                        </div>
                                        }
                                        <div className='col-sm-12'>
                                        <div className="did-floating-label-content">
                                            <input type="text" className='style-input did-floating-input' id="video_url" name="video_url" value={inputValues?.video_url} onChange={(e) => handleChange(e)} placeholder=' '/>
                                            <label className="did-floating-label">Video URL</label>
                                            {validation.video_url && <p className='formErrors mb-2'>{validation.video_url}</p>}
                                       </div>
                                        </div>
                                        <div className='col-sm-12'>
                                            <label className='style-label'>Vehicle Documents Upload <span className='accepted-file' data-testid="accepted-file">(pdf/docx/png/jpg/jpeg)</span></label>
                                            <div className='row'>
                                                <div className='col-sm-6'>
                                                    <div className="box" onChange={uploadRegistration}>
                                                        <input type="file" id="registration" name='registration' className="inputfile" />
                                                        <label htmlFor="registration"><img src="/images/upload.png" alt="upload"/><span>{registration.name ? registration.name : 'Upload Registration'}</span></label>
                                                        {(equipments?.attributes?.upload_registartion) && (<a href={equipments?.attributes?.upload_registartion} target="_blank"><img src={'/images/file.png'} width={'32'} height={'32'} alt='upload' /></a>) }
                                                    </div>
                                                    {validation.registration && <p className='formErrors mb-2'>{validation.registration}</p>}
                                                    {Object.keys(resultMessage).filter((items) => {
                                                            return (resultMessage[items].split(' ')[1] === 'registartion')
                                                        }).map((item, index) => {
                                                            return resultMessage && <p className='formErrors p-2'>{resultMessage[item]}</p>
                                                        })}
                                                </div>
                                                <div className='col-sm-6'>
                                                    <div className="box" onChange={uploadInsurance}>
                                                        <input type="file" id="insurance" name='insurance' className="inputfile" />
                                                        <label htmlFor="insurance"><img src="/images/upload.png" alt="upload"/><span>{insurance.name ? insurance.name : 'Upload Insurance'}</span></label>
                                                        {equipments?.attributes?.upload_insurance !== '' && (<a href={equipments?.attributes?.upload_insurance} target="_blank"><img src={'/images/file.png'} width={'32'} height={'32'} alt='upload' /></a>) }
                                                    </div>
                                                    {validation.insurance && <p className='formErrors mb-2'>{validation.insurance}</p>}
                                                    {Object.keys(resultMessage).filter((items) => {
                                                            return (resultMessage[items].split(' ')[1] === 'insurance')
                                                        }).map((item, index) => {
                                                            return resultMessage && <p className='formErrors p-2'>{resultMessage[item]}</p>
                                                        })}
                                                </div>
                                                <div className='col-sm-6'>
                                                    <div className="box" onChange={uploadTOV}>
                                                        <input type="file" id="TOV" name='TOV' className="inputfile" />
                                                        <label htmlFor="TOV"><img src="/images/upload.png" alt="upload"/><span>{TOV.name ? TOV.name : 'Upload TOV Certificate'}</span></label>
                                                        {equipments?.attributes?.upload_tov_certificate !== '' && (<a href={equipments?.attributes?.upload_tov_certificate} target="_blank"><img src={'/images/file.png'} width={'32'} height={'32'} alt='upload' /></a>) }
                                                    </div>
                                                    {validation.TOV && <p className='formErrors mb-2'>{validation.TOV}</p>}
                                                    {Object.keys(resultMessage).filter((items) => {
                                                            return (resultMessage[items].split(' ')[1] === 'tov')
                                                        }).map((item, index) => {
                                                            return resultMessage && <p className='formErrors p-2'>{resultMessage[item]}</p>
                                                        })}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-sm-12'>
                                            <label className='style-label'>Operator Documents Upload</label>
                                            <div className='row'>
                                                <div className='col-sm-6'>
                                                    <div className="box" onChange={uploadOperaterId}>
                                                        <input type="file" id="operaterId" name='operaterId' className="inputfile" />
                                                        <label htmlFor="operaterId"><img src="/images/upload.png" alt="upload"/><span>{operaterId.name ? operaterId.name : 'Operater ID'}</span></label>
                                                        {equipments?.attributes?.operator_file !== '' && (<a href={equipments?.attributes?.operator_file} target="_blank"><img src={'/images/file.png'} width={'32'} height={'32'} alt='upload' /></a>) }
                                                    </div>
                                                    {validation.operaterId && <p className='formErrors mb-2'>{validation.operaterId}</p>}
                                                    {Object.keys(resultMessage).filter((items) => {
                                                            return (resultMessage[items].split(' ')[1] === 'file')
                                                        }).map((item, index) => {
                                                            return resultMessage && <p className='formErrors p-2'>{resultMessage[item]}</p>
                                                        })}
                                                </div>
                                                <div className='col-sm-6'>
                                                    <div className="box" onChange={uploadOperaterCertificate}>
                                                        <input type="file" id="operaterCertificate" name='operaterCertificate' className="inputfile" />
                                                        <label htmlFor="operaterCertificate"><img src="/images/upload.png" alt="upload"/><span>{operaterCertificate.name ? operaterCertificate.name : 'Operater Certificate'}</span></label>
                                                        {equipments?.attributes?.operator_certificate !== '' && (<a href={equipments?.attributes?.operator_certificate} target="_blank"><img src={'/images/file.png'} width={'32'} height={'32'} alt='upload' /></a>) }
                                                    </div>
                                                    {validation.operaterCertificate && <p className='formErrors mb-2'>{validation.operaterCertificate}</p>}
                                                    {Object.keys(resultMessage).filter((items) => {
                                                            return (resultMessage[items].split(' ')[1] === 'certificate')
                                                        }).map((item, index) => {
                                                            return resultMessage && <p className='formErrors p-2'>{resultMessage[item]}</p>
                                                        })}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-sm-12'>
                                            <label className='style-label'>Define Price in SAR (to hire instantly by customer)</label>
                                            <div className='row'>
                                                <div className='col-sm-4'>
                                                <div className="did-floating-label-content">
                                                    <input type="text"
                                                      ref={(input) => {
                                                        inputRefs.current.price_per_day = input
                                                      }}
                                                    className='style-input did-floating-input' value={inputValues.price_per_day ? formatCurrency(inputValues.price_per_day):0}   name="price_per_day" id="price_per_day" placeholder=' ' onChange={(e) => handlePriceChange(e)}/>
                                                    <label className="did-floating-label">Price per day</label>
                                                    {validation.price_per_day && <p className='formErrors mb-2'>{validation.price_per_day}</p>}
                                                </div>
                                                </div>
                                                <div className='col-sm-4'>
                                                <div className="did-floating-label-content">
                                                    <input type="text" 
                                                     ref={(input) => {
                                                        inputRefs.current.price_per_week = input
                                                      }}
                                                    className='style-input did-floating-input' value={inputValues.price_per_week? formatCurrency(inputValues.price_per_week):0}  name="price_per_week" id="price_per_week" placeholder=' ' onChange={(e) => handlePriceChange(e)}/>
                                                     <label className="did-floating-label">Price per Week</label>
                                                    {validation.price_per_week && <p className='formErrors mb-2'>{validation.price_per_week}</p>}
                                              </div>
                                                </div>
                                                <div className='col-sm-4'>
                                                <div className="did-floating-label-content">
                                                    <input type="text" 
                                                    ref={(input) => {
                                                        inputRefs.current.price_per_month = input
                                                      }}
                                                    className='style-input did-floating-input' value={inputValues.price_per_month?formatCurrency(inputValues.price_per_month):0}  name="price_per_month" id="price_per_month" placeholder=' ' onChange={(e) => handlePriceChange(e)}/>
                                                    <label className="did-floating-label">Price per Month</label>
                                                    {validation.price_per_month && <p className='formErrors mb-2'>{validation.price_per_month}</p>}
                                               </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-sm-12'>
                                            <label className="checkbox-style my-3" style={{visibility:'hidden'}}>
                                                <span className="small">Lorem ipsum dolor sit amet consectetur, adipiscing elit ullamcorper condimentum, a senectus vivamus magnis.</span>
                                                <input type="checkbox" checked={true} onChange={e => setChecked(e.target.checked)}/>
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>
                                        <div className='btn-groups'>
                                            {isLoading ? <div className='position-relative'><button className="link-buttons" disabled={true} type="button">Save</button> <img className='position-absolute' style={{"top" : "20%", "right" : "11%"}} src='/images/default.gif' width={'16px'} height={'16px'}/></div> :
                                                <button type="button" className='link-buttons' onClick={() => {editEquipment('in_draft')}}>Save</button>
                                            }
                                            <Link to={'/manage-equipment'} className='link-buttons'>Cancel</Link>
                                            {isLoading ? <div className='position-relative'><button className="submit-buttons" disabled={true} type="button">Submit for Review</button> <img className='position-absolute' style={{"top" : "30%", "right" : "4%"}} src='/images/default.gif' width={'16px'} height={'16px'}/></div> :
                                                <button type="submit" className='submit-buttons'>Submit for Review</button>
                                            }
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default EditEquipment;