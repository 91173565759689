import React from "react";

function ChatSectionItem({username,usermessage,timestatus={}, img, isOnline, unreadMessages}) {

  const vatidatedTimeStatus = timestatus?.last_message?.attributes?.created_at 
  ? timestatus?.last_message?.attributes?.created_at.replace("about", "").replace("minutes", "min").trim() 
  : timestatus?.created_at?.replace("about", "").replace("minutes", "min").trim();
  const toggleStyles = unreadMessages ? "unreadMessage" : "readMessage";
  
    const firstLetter = username ? username.charAt(0).toUpperCase() : "?";
    const color = "#ff8d27"

  return (
    <>
      <div className="dashboardMessagesItemContainer underscore">
        <div className="dashboardMessageitemContent">
          <div className={`${isOnline === true ? "dashboardChatAvatar" : "offline"}`}>
          {img ? (
        <img
          src={img}
          alt={username}
          className="dashBoardImg"
        />
      ) : (
        <div
          style={{
            width: 40,
            height: 40,
            borderRadius: '50%',
            backgroundColor: color,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'white',
            fontSize: 24,
          }}
        >
          {firstLetter}
        </div>
      )}
          </div>
          <div className="messagesItemContentDashboard">
            <div className="infoSectionDashboard">
              <p className="messagesUserName">{username}</p>
              <p className={`messagePreviewDashboard ${toggleStyles}`}>
                {usermessage ? usermessage : 'No messages yet'}
              </p>
            </div>
          </div>
        </div>
        <div className={`timeStatusDashboard ${toggleStyles}`}>
          <p>{vatidatedTimeStatus}</p>
          <div className={`${unreadMessages ? 'readStatusDashboard' : ''}`}></div>
        </div>
      </div>
    </>
  );
}

export default ChatSectionItem;
