import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import Header from '../Header';
import Sidebar from '../Sidebar';
import axios from 'axios';
import appConstant from '../../shared/constant/constant.json';
import LoadingSpinner from '../../shared/Loader';
function ManageEquipment() {
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        equipmentList(1);
        checkVendorDetails();
    }, []);
    const [equipments, setEquipments] = React.useState({});
    const [paginationPages, setPaginationPages] = React.useState([]);
    const [isVendorCanCreateEquipment, setIsVendorCanCreateEquipment] = React.useState(true);
    const params = {
        headers: {
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": '*',
            "token": localStorage.getItem('token')
        }
    };
    const equipmentList = (page) => {
        setIsLoading(true);
        axios.get(`${appConstant.API_URL}/bx_block_catalogue/manage_equipments?page=${page}&limit=20`, params)
            .then(res => {
                setIsLoading(false);
                if (res.data.status === 200) {
                    setEquipments(res.data);
                    const row = [];
                    for (var i = 1; i <= parseInt(res.data.total_pages); i++) {
                        row.push(i);
                    }
                    setPaginationPages(row);
                }else {
                }
            })
            .catch(error => {
                setIsLoading(false);
            });
    }
    const checkVendorDetails = () => {
        axios.get(`${appConstant.API_URL}/account_block/get_profile_status`, params)
            .then(res => {
                if (res.data.status === 200 && res.data.profile_status !== 'in_review') {
                    setIsVendorCanCreateEquipment(true);
                }else {
                    setIsVendorCanCreateEquipment(false);
                }
            })
            .catch(error => {
                setIsLoading(false);
            });
    }

    const equipmentStatus = (equipmentId, status, page) => {
        axios.put(`${appConstant.API_URL}/bx_block_catalogue/equipment_availability?id=${equipmentId}&status=${status}`, '', params)
            .then(res => {
                if (res.data.status === 200) {
                    equipmentList(page);
                }else {
                }
            })
            .catch(error => {
            });
    }
    return (
        <>
            <Header/>
            <div className='blank'></div>
            <div className='container py-4'>
                <div className='row'>
                    <div className='col-md-3'>
                        <Sidebar id="3" />
                    </div>
                    <div className='col-md-9'>
                        <div className='rightSide'>
                            <div className='breadcrumbs'>
                                <span className='active-page' data-testid="manage-quipment-title">Manage Equipment</span>
                                {isVendorCanCreateEquipment && <Link to="/manage-equipment/add-new-equipment" className='breadButton'>+ Add New Equipment</Link>}
                                {!isVendorCanCreateEquipment && <div><button className='breadButton' disabled={true}>+ Add New Equipment</button><div className={'formErrors text-end mt-3'}>Account is under review.</div></div>}
                            </div>
                            {isLoading ? <LoadingSpinner/> :
                                <div>
                                    <div className="table-responsive alyya-table">
                                        <table className="table table-borderless">
                                            <thead>
                                            <tr>
                                                <th className='min-300'>Equipment</th>
                                                <th className='min-210'>Type</th>
                                                <th className='min-250'>Capacity</th>
                                                <th className='min-250'>Availability</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {equipments.data?.length > 0 && equipments.data?.map((result, i) =>
                                                    <>
                                                        <tr key={i}>
                                                            <td className='min-300'>
                                                                <div className='d-flex align-items-center'><img
                                                                    src={result.attributes?.images && result.attributes?.images[0]?.url} className=""
                                                                    alt="equipment"/>
                                                                    <strong>{result.attributes?.product_name} <span>{result.attributes?.model ? '-' : '' }</span> {result.attributes?.model}</strong>
                                                                </div>
                                                            </td>
                                                            <td 
                                                                style={{textTransform: 'capitalize'}} className='min-250'>{result.attributes?.sub_category_name}</td>

                                                            <td className='min-250'> {(result.attributes?.capacity.value) ? result.attributes?.capacity.value: '0'}</td>
                                                            <td className='min-250'>
                                                                <div className='d-flex align-items-center justify-content-between'>
                                    <span
                                        className={result.attributes?.status === "unavailable" ? 'dot red' : (result.attributes?.status === "available" ? 'dot green' : 'dot yellow')}></span>
                                                                    <span
                                                                        style={{textTransform: 'capitalize'}}>{result.attributes?.status?.replace('_', ' ')}</span>
                                                                    <div className="dropdown dropdown-menu-end">
                                                                        <button type="button" className="table-dot-button dropdown-toggle"
                                                                                data-bs-toggle="dropdown">
                                                                            <span className='dot black'></span><span className='dot black'></span><span
                                                                            className='dot black'></span>
                                                                        </button>
                                                                        <ul className="dropdown-menu" >
                                                                            <li><Link to={'/manage-equipment/edit-equipment/' + result.id}
                                                                                      className="dropdown-item">Edit Details</Link></li>
                                                                            {result.attributes?.status === "available" && <li onClick={() => {
                                                                                equipmentStatus(result.id, 'unavailable', equipments.current_page)
                                                                            }}><a className="dropdown-item">Make Unavailable</a></li>}
                                                                            {result.attributes?.status === "unavailable" && <li onClick={() => {
                                                                                equipmentStatus(result.id, 'available', equipments.current_page)
                                                                            }}><a className="dropdown-item">Make Available</a></li>}
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </>
                                            )}
                                            </tbody>
                                        </table>
                                    </div>
                                    {equipments.data?.length > 0 
                                        ? 
                                            <ul className="pagination justify-content-between alyya-pagination">
                                                {equipments.current_page === 1 &&
                                                    <li className="page-item disabled"><a className="page-link">&#129120; &nbsp; Previous</a></li>}
                                                {equipments.current_page !== 1 && <li className="page-item" onClick={() => {
                                                    equipmentList(equipments.current_page - 1)
                                                }}><a className="page-link">&#129120; &nbsp; Previous</a></li>}
                                                <div className='d-flex'>
                                                    {paginationPages?.length > 0 && paginationPages?.map((result, i) =>
                                                        <>
                                                            {result === equipments.current_page &&
                                                                <li className="page-item"><a className="page-link active">{result}</a></li>}
                                                            {result !== equipments.current_page &&
                                                                <li className="page-item"><a className="page-link" onClick={() => {
                                                                    equipmentList(result)
                                                                }}>{result}</a></li>}

                                                        </>
                                                    )}
                                                </div>

                                                {equipments.current_page === equipments.total_pages &&
                                                    <li className="page-item disabled"><a className="page-link">Next &nbsp; &#129122;</a></li>}
                                                {equipments.current_page !== equipments.total_pages && <li className="page-item" onClick={() => {
                                                    equipmentList(equipments.current_page + 1)
                                                }}><a className="page-link">Next &nbsp; &#129122;</a></li>}
                                            </ul>
                                        : 
                                            <div className='manageEquipmentEmpty'>
                                                <h2>No equipment listed</h2>
                                                <p>You have not added any equipment</p>
                                                <p>click on "Add New Equipment" button to add equipment</p>
                                            </div>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default ManageEquipment;
