import React from 'react';
import Header from './Header';
import HireFooter from '../hireEquipment/HireFooter';
import Sidebar from './Sidebar';
import AboutUs from "../hireEquipment/AboutUs"

function AppAbout() {
  const showFooter= true
  return (
    <>
    <Header/>
    <div className='blank'></div>
      <div className='container py-4'>
        <div className='row'>
          <div className='col-md-3'>
            <Sidebar id="1"/>
          </div>
          <div className='col-md-9'>
            <div className='rightSide'>
              <div className='breadcrumbs'>
                  <span className='active-page AboutUsNew'><AboutUs showFooter={showFooter}/></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <HireFooter/>
    </>
  )
};

export default AppAbout;
