export const handlePresenceUpdate = (data, currentAuthId, callback) => {
  const now = new Date(); // Поточний час

  data.forEach((user) => {
    if (user.attributes.unique_auth_id !== currentAuthId) {
      const lastRequestAt = user.attributes.last_request_at;
      let isOnline = false;

      if (!lastRequestAt) {
        isOnline = false;
      } else {
        // Якщо є last_request_at, перевіряємо, чи пройшло більше 5 хвилин
        const lastRequestDate = new Date(lastRequestAt);
        const differenceInMinutes = (now - lastRequestDate) / (1000 * 60); // Різниця в хвилинах

        if (differenceInMinutes <= 5) {
          isOnline = true; 
        } else {
          isOnline = false; 
        }
      }

      callback(user.attributes.full_name, isOnline);
    }
  });
};