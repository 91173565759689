
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Header from './Header';
import axios from 'axios';
import LoadingSpinner from '../shared/Loader';
import appConstant from '../shared/constant/constant.json';
import { getFileNameFromUrl } from "../utils"
import "../admin/css/registration.css"







function RegisterOrganization() {
    useEffect(() => {
        VendorCompanyData();
    }, []);
    const nav = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [resultMessage, setResultMessage] = useState('');
    const [fileValidation, setFileValidation] = useState({
        gosi: '',
        saudization: '',
        zakat: '',
        company: ''
    });
    const params = {
        headers: {
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": '*',
            "token": localStorage.getItem('token')
        }
    };
    const [organization, setOrganization] = useState({
        organization_name: "",
        contact_person: "",
        contact_number: "",
        email: "",
        number_of_employees: "",
        vat: "",
        registration: "",
        address: "",
        inception: "2023",
        description: ""
    });
    const [orgValidation, setOrgValidation] = useState({
        organization_name: "",
        contact_person: "",
        contact_number: "",
        email: "",
        number_of_employees: "",
        vat: "",
        registration: "",
        address: "",
        inception: "",
        description: "",
        GOSI: "",
        saudization: "",
        zakat: "",
        company: ""
    });
    const [GOSI, setGOSI] = useState({ name: '' });
    const [saudization, setSaudization] = useState({ name: '' });
    const [zakat, setZakat] = useState({ name: '' });
    const [company, setCompany] = useState({ name: '' });
    const [checked, setChecked] = useState(false);
    const [companyDetails, setCompanyDetails] = useState(false);

    function changeValue(e) {
        const { name, value } = e.target;
        setOrganization({ ...organization, [name]: value });
    }
    const uploadGOSI = event => {
        const fileData = event.target.files[0];
        if (fileData !== undefined) {
            setGOSI(fileData);
        }
    }
    const uploadSaudization = event => {
        const fileData = event.target.files[0];
        if (fileData !== undefined) {
            setSaudization(fileData);
        }
    }
    const uploadZakat = event => {
        const fileData = event.target.files[0];
        if (fileData !== undefined) {
            setZakat(fileData);
        }
    }
    const uploadCompany = event => {
        const fileData = event.target.files[0];
        if (fileData !== undefined) {
            setCompany(fileData);
        }
    }
    const checkOrgValidation = () => {
        // let errors = JSON.parse(JSON.stringify(orgValidation));
        let errors = {...orgValidation}
        //organization_name validation
        const alphabetCond = /^(?! )[\w ]+$/;
        if (!organization.organization_name) {
            errors.organization_name = "Organization Name is required";
        } else if (!organization.organization_name.match(alphabetCond)) {
            errors.organization_name = "Organization Name is not valid";
        } else if (organization.organization_name.length < 2) {
            errors.organization_name = "Organization Character minimum length is 2";
        }
        else {
            errors.organization_name = "";
        }
        //contact_person validation
        const alphabetCondtn = /^(?! )[\w ]+$/;
        if (!organization.contact_person) {
            errors.contact_person = "Contact Person is required";
        } else if (!organization.contact_person.match(alphabetCondtn)) {
            errors.contact_person = "Contact Person is not valid";
        } else if (organization.contact_person.length < 2) {
            errors.contact_person = "Contact Person Character minimum length is 2";
        }
        else {
            errors.contact_person = "";
        }
        //contact_number validation
        const numberCond = /^(?:\+966|0)?\d{9}$/;
        if (!organization.contact_number) {
            errors.contact_number = "Contact Number is required";
        } else if (!String(organization.contact_number).match(numberCond)) {
            errors.contact_number = "Please enter 9 digit or 0 followed by 9 digit or +966 followed by 9 digit";
        } else if (organization.contact_number.length === 9 && organization.contact_number.toString().charAt(0) === '0') {
            errors.contact_number = 'Please enter valid number';
        }
        else {
            errors.contact_number = "";
        }
        //email validation
        const emailCond = /^[a-zA-Z0-9.!#$%&’*+=?^_`{|}~-]+@[a-zA-Z0-9_-]+(?:\.[a-zA-Z0-9-]{2,})*$/;
        if (!organization.email) {
            errors.email = "Email is required";
        } else if (!organization.email.match(emailCond)) {
            errors.email =`Please enter valid Email address`;
        }
        else {
            errors.email = "";
        }
        //number_of_employees validation
        if (!organization.number_of_employees) {
            errors.number_of_employees = "No. of Employees is required";
        } else if (isNaN(organization.number_of_employees)) {
            errors.number_of_employees = "Number of Employee should be numeric"

        } else {
            errors.number_of_employees = "";
        }
        //vat validation
        const vatCond = /^(?!0{15})\d{15}$/
        if (!organization.vat) {
            errors.vat = "Vat is required";
        } else if (isNaN(organization.vat)) {
            errors.vat = "Vat should be numeric"
        } else if (!organization.vat.match(vatCond)) {
            errors.vat = "Please enter 15 digit numeric"
        } else {
            errors.vat = "";
        }
        //registration validation
        const registrationCond = /^(?!0{10})\d{10}$/
        if (!organization.registration) {
            errors.registration = "Registration is required";
        } else if (isNaN(organization.registration)) {
            errors.registration = "Registration should be numeric"
        } else if (!organization.registration.match(registrationCond)) {
            errors.registration = "Please enter 10 digit numeric"
        } else {
            errors.registration = "";
        }
        //address validation
        if (!organization.address) {
            errors.address = "Address is required";
        }
        else {
            errors.address = "";
        }
        //inception validation
        if (organization.inception === '') {
            errors.inception = "Inception is required";
        } else {
            errors.inception = "";
        }
        //description validation

        // terms checked errors
        // if (!checked) {
        //     errors.checkedError = 'Please select';
        // } else {
        //     errors.checkedError = '';
        // }
        //GOSI validation
        if (!GOSI.name && fileValidation.gosi === '') {
            errors.GOSI = "Please upload document";
        } else {
            errors.GOSI = "";
        }
        //saudization validation
        if (!saudization.name && fileValidation.saudization === '') {
            errors.saudization = "Please upload document";
        } else {
            errors.saudization = "";
        }
        //zakat validation
        if (!zakat.name && fileValidation.zakat === '') {
            errors.zakat = "Please upload document";
        } else {
            errors.zakat = "";
        }
        //company validation
        if (!company.name && fileValidation.company === '') {
            errors.company = "Please upload document";
        } else {
            errors.company = "";
        }
        setOrgValidation(errors);
        // if (errors.organization_name === '' && errors.contact_person === '' && errors.contact_number === '' && errors.email === '' && errors.number_of_employees === '' && errors.vat === '' && errors.registration === '' && errors.address === '' && errors.inception === '' && errors.description === '' && errors.GOSI === '' && errors.saudization === ''  && errors.zakat === '' && errors.company === ''  && errors.checkedError === ''){
        // if (errors.organization_name === '' && errors.contact_person === '' && errors.contact_number === '' && errors.email === '' && errors.number_of_employees === '' && errors.vat === '' && errors.registration === '' && errors.address === '' && errors.inception === '' && errors.description === '' && errors.GOSI === '' && errors.saudization === '' && errors.zakat === '' && errors.company === '') {
        //     return true;
        // } else {
        //     return false;
        // }
        const hasErrors = Object.values(errors).some(error => error !== '');
        return !hasErrors;
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        setOrgValidation({
            organization_name: "",
            contact_person: "",
            contact_number: "",
            email: "",
            number_of_employees: "",
            vat: "",
            registration: "",
            address: "",
            inception: "",
            description: "",
            GOSI: "",
            saudization: "",
            zakat: "",
            company: ""
        });
        const isSuccess = checkOrgValidation();
        if (isSuccess) {
            updateCompanyDetails('in_review');
        }
    };

    const updateCompanyDetails = (status) => {
        setOrgValidation({
            organization_name: "",
            contact_person: "",
            contact_number: "",
            email: "",
            number_of_employees: "",
            vat: "",
            registration: "",
            address: "",
            inception: "",
            description: "",
            GOSI: "",
            saudization: "",
            zakat: "",
            company: ""
        });
        setIsLoading(true);
        let companyData = new FormData();
        if (organization.organization_name) {
            companyData.append('data[organisation_name]', organization.organization_name);
        }
        if (organization.contact_person) {
            companyData.append('data[contact_person]', organization.contact_person);
        }
        if (organization.contact_number) {
            companyData.append('data[contact_number]', organization.contact_number);
        }
        if (organization.email) {
            companyData.append('data[email]', organization.email);
        }
        if (organization.number_of_employees) {
            companyData.append('data[number_of_employees]', organization.number_of_employees);
        }
        if (organization.vat) {
            companyData.append('data[vat]', organization.vat);
        }
        if (organization.registration) {
            companyData.append('data[registration]', organization.registration);
        }
        if (organization.address) {
            companyData.append('data[address]', organization.address);
        }
        if (organization.description) {
            companyData.append('data[description]', organization.description);
        }
        if (organization.inception) {
            companyData.append('data[year_of_inception]', organization.inception);
        }
        if (GOSI.name) {
            companyData.append('data[gosi_certificate]', GOSI);
        }
        if (saudization.name) {
            companyData.append('data[saudization_certificate]', saudization);
        }
        if (zakat.name) {
            companyData.append('data[zakat_certificate]', zakat);
        }
        if (company.name) {
            companyData.append('data[company_registration]', company);
        }
        companyData.append('data[status]', status);
        const params = {
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "token": localStorage.getItem('token')
            }
        };

        axios.post(`${appConstant.API_URL}/bx_block_profile/registrations`, companyData, params)
            .then(res => {
                setIsLoading(false);
                if (res.data.status === 200) {
                    localStorage.setItem("registration", "true")
                    localStorage.setItem("registrationStatus", status)

                    if (status === "in_review" || status === "approved") {
                        nav('/dashboard',{state:{id:2}});
                    }

                } else {
                    setResultMessage(res.data.message);
                }
            })
            .catch(error => {
                setIsLoading(false);
                // props.changeLogin(true);
            });



    }
    const VendorCompanyData = () => {
        setIsLoading(true);
        axios.get(`${appConstant.API_URL}/bx_block_profile/show_registration`, params)
            .then(res => {
                setIsLoading(false);
                if (res.data.status === 200) {
                    setCompanyDetails(res?.data?.data);
                    const data = {
                        organization_name: res?.data?.data?.attributes?.organisation_name,
                        contact_person: res?.data?.data?.attributes?.contact_person,
                        contact_number: res?.data?.data?.attributes?.contact_number,
                        email: res?.data?.data?.attributes?.email,
                        number_of_employees: res?.data?.data?.attributes?.number_of_employees,
                        vat: res?.data?.data?.attributes?.vat,
                        registration: res?.data?.data?.attributes?.registration,
                        address: res?.data?.data?.attributes?.address,
                        inception: res?.data?.data?.attributes?.year_of_inception,
                        description: res?.data?.data?.attributes?.description
                    }
                    let saudization_certificate = getFileNameFromUrl(res?.data?.data?.attributes?.saudization_certificate)
                    let gosi = getFileNameFromUrl(res?.data?.data?.attributes?.gosi_certificate)
                    let zakat = getFileNameFromUrl(res?.data?.data?.attributes?.zakat_certificate)
                    let company = getFileNameFromUrl(res?.data?.data?.attributes?.company_registration)

                    setSaudization(saudization_certificate)
                    setZakat(zakat)
                    setGOSI(gosi)
                    setCompany(company)
                    updateCompanyDetails(res?.data?.data?.attributes?.status)
                    setOrganization(data);
                    const fileData = {
                        gosi: res?.data?.data?.attributes?.gosi_certificate ? res?.data?.data?.attributes?.gosi_certificate : '',
                        saudization: res?.data?.data?.attributes?.saudization_certificate ? res?.data?.data?.attributes?.saudization_certificate : '',
                        zakat: res?.data?.data?.attributes?.zakat_certificate ? res?.data?.data?.attributes?.zakat_certificate : '',
                        company: res?.data?.data?.attributes?.company_registration ? res?.data?.data?.attributes?.company_registration : ''
                    }
                    setFileValidation(fileData);
                    YearsOfManufactures(res?.data?.data?.attributes?.year_of_inception);
                } else {
                }
            })
            .catch(error => {
                setIsLoading(false);
            });
    }
    const currentYear = (new Date()).getFullYear();
    const YearsOfManufactures = (defaultYear = '') => {
        const row = [];
        for (var i = 1900; i <= parseInt(currentYear); i++) {
            if (defaultYear && parseInt(defaultYear) === i) {
                row.push(<option value={i} key={i} selected='selected'>{i}</option>);
            } else {
                row.push(<option value={i} key={i}>{i}</option>);
            }
        }
        return row;
    }
    return (
        <>
            <Header />
            <div className='blank'></div>
            <div className='registerOrganization'>
                <h3 className='title'>Register Your Organization</h3>
                {isLoading ? <LoadingSpinner /> :
                    <form className='alyya-form' testID='submit_Form' onSubmit={handleSubmit}>
                        {/* {resultMessage && <p className='formErrors'>{resultMessage}</p>} */}
                        <div className='row'>
                            <div className='col-sm-12'>
                                <div className="did-floating-label-content">
                                     <input type="text" className=" style-input did-floating-input" label="Organization Name"
                                    testID="register_organization_name"
                                    value={organization.organization_name} name="organization_name"
                                    id="organization_name" placeholder=' '
                                    onChange={(e) => changeValue(e)} />
                                  <label className="did-floating-label">Organization Name</label>
                                {orgValidation.organization_name ? 
                                    <p className='formErrors mb-2' testID="register_organization_name_err" >{orgValidation.organization_name}</p> 
                                    :
                                    Object.keys(resultMessage).filter((items) => {
                                        return (resultMessage[items].split(' ')[0] === 'Organisation')
                                    }).map((item, index) => {
                                        return resultMessage && <p className='formErrors p-2'>{resultMessage[item]}</p>
                                    })}
                                </div> 
                            </div>

                            <div className='col-sm-6'>
                                <div className="did-floating-label-content">
                                    <input type="text" className='style-input did-floating-input'
                                        testID="register_contact_person"
                                        value={organization.contact_person} name="contact_person"
                                        id="contact_person" placeholder=' '
                                        onChange={(e) => changeValue(e)} />
                                    <label className="did-floating-label">Contact person</label>
                                    {orgValidation.contact_person ? 
                                        <p className='formErrors mb-2' testID="register_contact_person_err" >{orgValidation.contact_person}</p> 
                                        : 
                                        Object.keys(resultMessage).filter((items) => {
                                        return (resultMessage[items].split(' ')[1] === 'person')
                                    }).map((item, index) => {
                                        return resultMessage && <p className='formErrors p-2'>{resultMessage[item]}</p>
                                    })}
                                    
                                </div>
                            </div>
                            <div className='col-sm-6'>
                                <div className="did-floating-label-content">
                                    <input type="text" className='style-input did-floating-input'
                                        value={organization.contact_number} name="contact_number"
                                        id="contact_number" placeholder=' '
                                        onChange={(e) => changeValue(e)} />
                                    <label className="did-floating-label">Contact Number</label>
                                    {orgValidation.contact_number ? 
                                        <p className='formErrors mb-2'>{orgValidation.contact_number}</p>
                                        :
                                        Object.keys(resultMessage).filter((items) => {
                                            return (resultMessage[items].split(' ')[1] === 'number')
                                        }).map((item, index) => {
                                            return resultMessage && <p className='formErrors p-2'>{resultMessage[item]}</p>
                                    })}
                                </div>
                            </div>
                            <div className='col-sm-6'>
                                <div className="did-floating-label-content">
                                    <input type="text" className='style-input did-floating-input' value={organization.email}
                                        data-testid="register_email"
                                        name="email" id="email" placeholder=' '
                                        onChange={(e) => changeValue(e)} />
                                        <label className="did-floating-label">Email</label>
                                    {orgValidation.email ? 
                                        <p className='formErrors mb-2' data-testid='register_email_err'>{orgValidation.email}</p>
                                        :
                                        Object.keys(resultMessage).filter((items) => {
                                            return (resultMessage[items].split(' ')[0] === 'Email')
                                        }).map((item, index) => {
                                            return resultMessage && <p className='formErrors p-2'>{resultMessage[item]}</p>
                                    })}
                                </div>
                            </div>
                            <div className='col-sm-6'>
                                <div className="did-floating-label-content">
                                    <input type="text" className='style-input did-floating-input'
                                        testID="register_number_of_employees"
                                        value={organization.number_of_employees}
                                        name="number_of_employees" id="number_of_employees"
                                        placeholder=' '
                                        onChange={(e) => changeValue(e)} />
                                        <label className="did-floating-label">Number of employees</label>
                                    {orgValidation.number_of_employees ? 
                                        <p className='formErrors mb-2' testID="register_number_of_employees_err" >{orgValidation.number_of_employees}</p>
                                        :
                                        Object.keys(resultMessage).filter((items) => {
                                            return (resultMessage[items].split(' ')[3] === "can't")
                                        }).map((item, index) => {
                                            return resultMessage && <p className='formErrors p-2'>{resultMessage[item]}</p>
                                    })}
                                </div>
                            </div>
                            <div className='col-sm-6'>
                                <div className="did-floating-label-content">
                                    <input type="text" className='style-input did-floating-input' id="vat" name="vat" testID="register_Vat_field"
                                        value={organization.vat} onChange={(e) => changeValue(e)}
                                        placeholder=' ' />
                                        <label className="did-floating-label">VAT</label>
                                    {orgValidation.vat ? 
                                        <p className='formErrors mb-2' testID="register_vat_field_err">{orgValidation.vat}</p>
                                        :
                                        Object.keys(resultMessage).filter((items) => {
                                            return (resultMessage[items].split(' ')[0] === 'Vat')
                                        }).map((item, index) => {
                                            return resultMessage && <p className='formErrors p-2'>{resultMessage[item]}</p>
                                    })}
                                </div>
                            </div>
                            <div className='col-sm-6'>
                                <div className="did-floating-label-content">
                                    <input type="text" className='style-input did-floating-input' id="registration" data-testid="register_registration_field"
                                        name="registration" value={organization.registration}
                                        onChange={(e) => changeValue(e)} placeholder=' ' />
                                        <label className="did-floating-label">Registration</label>
                                    {orgValidation.registration ? 
                                        <p className='formErrors mb-2' data-testid="register_registration_field_err">{orgValidation.registration}</p>
                                        :
                                        Object.keys(resultMessage).filter((items) => {
                                            return (resultMessage[items].split(' ')[0] === 'Registration')
                                        }).map((item, index) => {
                                            return resultMessage && <p className='formErrors p-2'>{resultMessage[item]}</p>
                                    })}
                                </div>
                            </div>
                            <div className='col-sm-6'>
                                <div className="did-floating-label-content">
                                    <input type="text" className='style-input did-floating-input' value={organization.address}
                                        name="address" id="address" placeholder=' '
                                        onChange={(e) => changeValue(e)} />
                                        <label className="did-floating-label">Address</label>
                                    {orgValidation.address ? 
                                        <p className='formErrors mb-2'>{orgValidation.address}</p>
                                        :
                                        Object.keys(resultMessage).filter((items) => {
                                            return (resultMessage[items].split(' ')[0] === 'Address')
                                        }).map((item, index) => {
                                            return resultMessage && <p className='formErrors p-2'>{resultMessage[item]}</p>
                                    })}
                                </div>
                            </div>
                            <div className='col-sm-6'>
                                <div className="did-floating-label-content">
                                    <select name="inception" className='style-input did-floating-select-bg did-floating-select' id="inception" testID="year_field"
                                    value={organization.inception} onChange={(e) => changeValue(e)}>
                                            
                                        {/* <option value="">Year of Inception</option> */}
                                        {YearsOfManufactures().sort((a,b)=>b.key-a.key)}
                                    </select>
                                    {organization?.inception && <label class="did-floating-label">Year of Inception</label>}
                                    {orgValidation.inception ? 
                                        <p className='formErrors mb-2' testID="year_field_err">{orgValidation.inception}</p>
                                        :
                                        Object.keys(resultMessage).filter((items) => {
                                            return (resultMessage[items].split(' ')[0] === 'Inception')
                                        }).map((item, index) => {
                                            return resultMessage && <p className='formErrors p-2'>{resultMessage[item]}</p>
                                    })}
                                </div>
                            </div>
                            <div className='col-sm-12'>
                                <label className='style-label'>Organization Documents Upload <span className='accepted-file' data-testid="valid-file-format">(pdf/docx/png/jpg/jpeg)</span></label>
                                <div className='row'>
                                    <div className='col-sm-6'>
                                        <div className="box" onChange={uploadGOSI}>
                                            <input type="file" id="GOSI" name='GOSI'
                                                className="inputfile" />
                                            <label htmlFor="GOSI"><img src="/images/upload.png"
                                                alt="upload" /><span>{GOSI.name ? GOSI.name : 'GOSI certificate'}</span></label>
                                            {(companyDetails?.attributes?.gosi_certificate) && (<a href={companyDetails?.attributes?.gosi_certificate} target="_blank"><img src={'/images/file.png'} width={'32'} height={'32'} alt='upload' /></a>)}
                                        </div>
                                        {orgValidation.GOSI ? 
                                            <p className='formErrors mb-2'>{orgValidation.GOSI}</p>
                                            :
                                            Object.keys(resultMessage).filter((items) => {
                                            return (resultMessage[items].split(' ')[0] === 'Gosi')
                                        }).map((item, index) => {
                                            return resultMessage && <p className='formErrors p-2'>{resultMessage[item]}</p>
                                        })}
                                    </div>
                                    <div className='col-sm-6'>
                                        <div className="box" onChange={uploadSaudization}>
                                            <input type="file" id="saudization" name='saudization'
                                                className="inputfile" />
                                            <label htmlFor="saudization"><img src="/images/upload.png"
                                                alt="upload" /><span>{saudization.name ? saudization.name : 'Saudization certificate'}</span></label>

                                            {(companyDetails?.attributes?.saudization_certificate) && (<a href={companyDetails?.attributes?.saudization_certificate} target="_blank"><img src={'/images/file.png'} width={'32'} height={'32'} alt='upload' /></a>)}
                                        </div>
                                        {orgValidation.saudization ?
                                            <p className='formErrors mb-2'>{orgValidation.saudization}</p>
                                            :
                                            Object.keys(resultMessage).filter((items) => {
                                            return (resultMessage[items].split(' ')[0] === 'Saudization')
                                        }).map((item, index) => {
                                            return resultMessage && <p className='formErrors p-2'>{resultMessage[item]}</p>
                                        })}
                                    </div>
                                    <div className='col-sm-6'>
                                        <div className="box" onChange={uploadZakat}>
                                            <input type="file" id="zakat" name='zakat'
                                                className="inputfile" />
                                            <label htmlFor="zakat"><img src="/images/upload.png" alt="upload" /><span>{zakat.name ? zakat.name : 'Zakat certificate'}</span></label>
                                            {(companyDetails?.attributes?.zakat_certificate) && (<a href={companyDetails?.attributes?.zakat_certificate} target="_blank"><img src={'/images/file.png'} width={'32'} height={'32'} alt='upload' /></a>)}
                                        </div>
                                        {orgValidation.zakat ?
                                            <p className='formErrors mb-2'>{orgValidation.zakat}</p>
                                            :
                                            Object.keys(resultMessage).filter((items) => {
                                                return (resultMessage[items].split(' ')[0] === 'Zakat')
                                            }).map((item, index) => {
                                                return resultMessage && <p className='formErrors p-2'>{resultMessage[item]}</p>
                                        })}
                                    </div>
                                    <div className='col-sm-6'>
                                        <div className="box" onChange={uploadCompany}>
                                            <input type="file" id="company" name='company'
                                                className="inputfile" />
                                            <label htmlFor="company"><img src="/images/upload.png"
                                                alt="upload" /><span>{company.name ? company.name : 'Company registration'}</span></label>
                                            {(companyDetails?.attributes?.company_registration) && (<a href={companyDetails?.attributes?.company_registration} target="_blank"><img src={'/images/file.png'} width={'32'} height={'32'} alt='upload' /></a>)}
                                        </div>
                                        {orgValidation.company ?
                                            <p className='formErrors mb-2'>{orgValidation.company}</p>
                                            :
                                            Object.keys(resultMessage).filter((items) => {
                                                return (resultMessage[items].split(' ')[0] === 'Company')
                                            }).map((item, index) => {
                                                return resultMessage && <p className='formErrors p-2'>{resultMessage[item]}</p>
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-12 mt-3'>
                            <div className="did-floating-label-content">
                                <textarea name="description" value={organization.description}
                                    onChange={(e) => changeValue(e)} className='style-input pt-2 did-floating-textarea'
                                    rows="5" placeholder=' '></textarea>
                                  <label className="did-floating-label">Add description</label>
                                {orgValidation.description &&
                                    <p className='formErrors mb-2'>{orgValidation.description}</p>}
                            </div>
                            </div>
                            <div className='col-sm-12'>
                                <label className="checkbox-style my-3" style={{ visibility: 'hidden' }}>
                                    <span className="small">Disclaimer if any Lorem Ipsum</span>
                                    <input type="checkbox" checked={true}
                                        onChange={e => setChecked(e.target.checked)} />
                                    <span className="checkmark"></span>
                                </label>
                                {/* {orgValidation.checkedError &&
                            <p className='formErrors mb-2'>{orgValidation.checkedError}</p>} */}
                            </div>
                            <div className='btn-groups'>
                                {isLoading ? <div className='position-relative'><button className="link-buttons" disabled={true} type="button">Save</button> <img className='position-absolute' style={{ "top": "20%", "right": "11%" }} src='/images/default.gif' width={'16px'} height={'16px'} /></div> :
                                    <button type="button" className='link-buttons' onClick={() => { updateCompanyDetails('in_draft') }}>Save</button>
                                }
                                <Link to={'/home/hire-equipment'} className='link-buttons'>Skip</Link>
                                {isLoading ? <div className='position-relative'><button className="submit-buttons" disabled={true} type="button">Submit for Review</button> <img className='position-absolute' style={{ "top": "30%", "right": "4%" }} src='/images/default.gif' width={'16px'} height={'16px'} /></div> :
                                    <button type="submit" className='submit-buttons' data-testid="sbumitButton">Submit for Review</button>
                                }
                            </div>
                        </div>
                    </form>
                }
            </div>
        </>
    )
};

export default RegisterOrganization;