import React, {useEffect, useState, useContext} from 'react';
import HireHeader from './HireHeader';
import HireFooter from './HireFooter';
import {Link} from 'react-router-dom';
import axios from 'axios';
import appConstant from '../shared/constant/constant.json';
import LoadingSpinner from '../shared/Loader';
import Moment from 'moment';
import { CountContext } from './CountContext';

function Favourites() {

  const [favouritesList ,setFavouritesItems] = React.useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [resultMessage, setResultMessage] = React.useState('');
  const [hideRemove, setHideRemove] = React.useState(true);
  const { favCount, favouriteCount } = useContext(CountContext);
  const [isRemovingAll, seIsRemovingAll] = React.useState(false);
  const [isRemoving, setIsRemoving] = React.useState(false);


  useEffect(() => {
    
  }, [localStorage.getItem('favouriteTotal')])

  const params = {
    headers: {
        "Content-Type": 'application/json',
        "Access-Control-Allow-Origin": '*',
        "token": localStorage.getItem('token')
    }
  };
  const favouriteList = () => {
    setIsLoading(true);
    axios.get(`${appConstant.API_URL}/bx_block_favourites/favourites`, params)
        .then(res => {
            setIsLoading(false);
            if (res.status === 200) {     
                setFavouritesItems(res.data.data)
                setResultMessage(res.data.message)
               if(!res.data.data) {
                setHideRemove(false)
               }
              
            }else {
             
            }
        })
        .catch(error => {
            setIsLoading(false);
        });
        }
        useEffect(() => {
          favouriteList();
          favouriteCount();
        }, []);
  const removeOneItem = (i) => {
    const id = i;
    setIsLoading(true);
    axios.put(`${appConstant.API_URL}/bx_block_favourites/favourites/remove_to_favourite?equipment_id=${id}`, {} , params)
        .then(res => {
            setIsLoading(false);
            if (res.status === 200) {
              setIsRemoving(true); 
              setTimeout(() => {
                setIsRemoving(false); 
              }, 3000);
               favouriteList()
               favouriteCount()
            }else {
               
            }
        })
        .catch(error => {
          setIsRemoving(false)
          setIsLoading(false);
        });
  }

    const removeAllItem = (list) => {
      const idArray = list.map((item) => Number(item.attributes.equipment_id));
      setIsLoading(true);
      axios.put(`${appConstant.API_URL}/bx_block_favourites/favourites/remove_all_favourites?equipment_ids=${JSON.stringify(idArray)}`, {} , params)
          .then(res => {
              setIsLoading(false);
              if (res.status === 200) {
                seIsRemovingAll(true); 
                setTimeout(() => {
                  seIsRemovingAll(false); 
                }, 3000);
                  favouriteList()
                  favouriteCount()
              }else {
                  
              }
          })
          .catch(error => {
              setIsLoading(false);
              seIsRemovingAll(false)
          });
          }
  return (
    <>
    <HireHeader/>
    <div className='filter-blank'></div>
    <div className='cart home-content'>
    {isLoading ? <LoadingSpinner/> :
            <div className='container'>
                <div className='breadcrumbs'>
                    <div><Link to="/home/hire-equipment"> Home /</Link> <strong>Favourites</strong></div>
                    <div className='removing-container'>
                      {!isRemovingAll ? "" : <span className='tiptextt'>All the equipments removed successfully</span>}
                      {!isRemoving ? "" : <span className='tiptextt'>Equipment removed successfully</span>}
                    </div>
                </div>
                {resultMessage && <p className='formErrors pb-2'>{resultMessage}</p>}
                <div className="table-responsive alyya-table mb-3">          
                    <table className="table table-borderless">
                      <thead>
                        <tr>
                          <th>Equipment</th>
                          <th>Type / Capacity</th>
                          <th>Added On</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                      {favouritesList?.length > 0 && favouritesList?.map((result, i) =>
                         <>
                        <tr>
                          <td style={{width: '35%'}}>
                            
                          <Link to={"/home/hire-equipment/view-equipment-detail/" + result.attributes.equipment_id}>
                            <div className='d-flex align-items-center'>
                                <img
                                  src={result?.attributes?.equipment?.data?.attributes.images?.length > 0 && result?.attributes?.equipment?.data?.attributes.images[0].url}
                                  className="" alt="equipment"/>
                              <div>
                                <strong className='d-block'>{result?.attributes?.equipment.data.attributes.product_name}</strong>
                                <span className='d-block mt-2'>{result?.attributes?.equipment.data.attributes.model}</span>
                              </div>
                            </div>
                          </Link>
                          </td>
                          <td>
                            <div className='justify-content-between align-items-center'>
                            <strong className='d-block mt-2'>{result?.attributes?.equipment?.data?.attributes.sub_category_name}</strong>
                            <span className='d-block mt-2'>{result?.attributes?.equipment?.data?.attributes?.capacity?.value}</span>
                            <span className='d-block mt-2'>Zone <strong>{result?.attributes?.equipment?.data?.attributes.base_location}</strong></span>
                            </div>
                            
                          </td>
                          <td className='text-center'>
                            <div className='justify-content-between align-items-center'>
                                <label className='d-block'>{Moment(result?.attributes?.created_at).format('DD-MM-YYYY')}</label>
                            </div>                                                      
                          </td>
                          <td><button className='delete-button' onClick={() => removeOneItem(result?.attributes?.equipment_id)}><img src="/images/trash.png" className="" alt="equipment"/>Remove</button></td>
                        </tr>    
                        </>
                      )}                   
                      </tbody>
                    </table>
                </div>
                {hideRemove && <button className='submit-btn mb-0' onClick={() => removeAllItem(favouritesList)}>Remove All</button>}
            </div>
            }
        </div>
    <HireFooter/>
    </>
  )
};

export default Favourites;