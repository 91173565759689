import React, { useState } from "react";
import closeIcon from "../../../public/images/close_icon.png";
import searchIcon from '../../../public/images/search.png';

function StartNewMessage ({isOpen, onCloseNewMessage, accountsForChat, onShowChat}) {

    const [userSearch, setUserSearch] = useState('');

    const closeNewMessage = () => {
        onCloseNewMessage(false);
    };

    const showChat = (chat) => {
        const {chat_id, image, full_name} = chat.attributes;
        const chatData = {
            id: chat_id,
            imageUrl: image,
            name: full_name
        };
        onShowChat(chatData);
        closeNewMessage();
        console.log('chat', chatData);
    };

    // Filter accounts based on the userSearch text
    const filteredAccounts = accountsForChat.filter((acc) =>
        acc.attributes.full_name.toLowerCase().includes(userSearch.toLowerCase())
    );

    return (
        <div className="newMessageContainer">
            <div className="newMessageTopSection">
                <p>New message</p>
                <button className="newMessageCloseButton" onClick={closeNewMessage}>
                    <img src={closeIcon} alt="Close" />
                </button>
            </div>
            <div className="inputField">
                <img src={searchIcon} alt="Search" />
                <input
                    type="text"
                    placeholder="Search users"
                    value={userSearch}
                    onChange={(e) => setUserSearch(e.target.value)}
                />
            </div>

            <ul className="newMessageOptions">
                {filteredAccounts.map((acc) => (
                    <li key={acc.id} className="newMessageOptionsItem" onClick={() => showChat(acc)}>
                        {acc.attributes.image ? (
                            <div className="containerWithImage">
                                <img className="dashBoardImg" src={acc.attributes.image} alt={acc.attributes.full_name[0]} />
                                <p>{acc.attributes.full_name}</p>
                            </div>
                        ) : (
                            <div className="containerWithoutImage">
                                <span className="dashBoardImg">{acc.attributes.full_name[0]}</span>
                                <p>{acc.attributes.full_name} </p>
                            </div>
                        )}
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default StartNewMessage;
