import React from 'react';
import Header from '../Header';
import Sidebar from '../Sidebar';

function Subscriptions() {
  return (
    <>
    <Header/>
    <div className='blank'></div>
      <div className='container py-4'>
        <div className='row'>
          <div className='col-md-3'>
            <Sidebar id="4"/>
          </div>
          <div className='col-md-9'>
            <div className='rightSide'> 
              <div className='breadcrumbs'>
                <span className='active-page'>Subscriptions</span>
              </div>
            </div>
          </div>
        </div>        
      </div>
    </>
  )
};

export default Subscriptions;