import React, { useEffect, useState } from 'react';
import Header from '../Header';
import Sidebar from '../Sidebar';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import appConstant from '../../shared/constant/constant.json';
import Moment from 'moment';
import LoadingSpinner from '../../shared/Loader';

function AdminOrderSummary() {
    const [pdfUrl, setPdfUrl] = useState("")
    const [isPdfGenerating, setIsPdfGenerating] = useState(false);
    const [attributeName, setAttributeName] = useState("")
    useEffect(() => {
        orderSummarydata();
    }, []);
    const props = useParams();
    const [orderSummary, setOrderSummary] = React.useState({});
    const params = {
        headers: {
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": '*',
            "token": localStorage.getItem('token')
        }
    };

    const generatePdfUrl = (name) => {
        setIsPdfGenerating(true)
        setAttributeName(name)
        axios.get(`${appConstant.API_URL}/bx_block_order_management/generate_order_summary_pdf?id=${props.id}`, params).then((res) => {
            const prdUrl = `${appConstant.API_URL}/${res.data.pdf_url}`
            const link = document.createElement('a');
            link.href = prdUrl;
            link.download = 'downloaded.pdf';
            link.click();
            setIsPdfGenerating(false)
        }).catch((e) => {
            console.log(e, "error")
            setIsPdfGenerating(false)
        })
    }

    const orderSummarydata = () => {
        axios.get(`${appConstant.API_URL}/bx_block_order_management/orders/order_summary?id=${props.id}`, params)
            .then(res => {
                if (res.data.status === 200) {
                    setOrderSummary(res?.data?.data);
                } else {
                }
            })
            .catch(error => {
            });
    }
    return (
        <>
            <Header />
            <div className='blank'></div>
            <div className='container py-4'>
                <div className='row'>
                    <div className='col-md-3'>
                        <Sidebar id="5" />
                    </div>
                    <div className='col-md-9'>
                        <div className='rightSide'>
                            <div className='orders home-content'>
                                <div className='container'>
                                    <div className='d-lg-flex justify-content-between align-items-center'>
                                        <div className='title'><Link to="/orders">Orders / </Link><span>Order Summary</span></div>
                                        <div className='title text-start'>
                                            <button className='order-btn-group m-0' onClick={() => generatePdfUrl("download invoice")}> <a className='generatePDf-loader'> {isPdfGenerating && attributeName === "download invoice" ? <LoadingSpinner /> : <img className='icons' src="/images/invoice.png" alt="Invoice" />}&nbsp;&nbsp; {isPdfGenerating && attributeName === "download invoice" ? "generating pdf..." : "Download Invoice"}</a></button>
                                            <button className='order-btn-group' onClick={() => generatePdfUrl("print")}> <a className='generatePDf-loader'>{isPdfGenerating && attributeName === "print" ? <LoadingSpinner /> : <img className='icons' src="/images/print.png" alt="Print" />}&nbsp;&nbsp; {isPdfGenerating && attributeName === "print" ? "generating pdf..." : "Print"}</a></button>
                                        </div>
                                    </div>
                                    <div className='title'>order summery</div>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <div className='quote-box'>
                                                <div className='quote-heading'>Quote requested date:</div>
                                                <div className='value-heading' data-testid="quote-req-date">{Moment(orderSummary?.attributes?.quote_requested_date).format("DD-MM-YYYY")}</div>
                                            </div>

                                            <div className='quote-box'>
                                                <div className='quote-heading' >Quote requested by:</div>
                                                <div className='value-heading' data-testid="quote-req-by">{orderSummary?.attributes?.quote_requested_by ? orderSummary?.attributes?.quote_requested_by : "-"}</div>
                                            </div>

                                            <div className='quote-box'>
                                                <div className='quote-heading'>Quote generated date:</div>
                                                <div className='value-heading' data-testid="quote-gen-date">{Moment(orderSummary?.attributes?.quote_generated_date).format("DD-MM-YYYY")}</div>
                                            </div>

                                        </div>
                                        <div className='col-md-4'>



                                            <div className='quote-box'>
                                                <div className='quote-heading'>Quote generated by:</div>
                                                <div className='value-heading' data-testid="quote-gen-by">{orderSummary?.attributes?.quote_generated_by ? orderSummary?.attributes?.quote_generated_by : "-"}</div>
                                            </div>

                                            <div className='quote-box'>
                                                <div className='quote-heading'>Vender billing address:</div>
                                                <div className='value-heading'>{orderSummary?.attributes?.vendor_billing_address ? orderSummary?.attributes?.vendor_billing_address : "-"}</div>
                                            </div>

                                        </div>
                                        <div className='col-md-4'>
                                            <div className='quote-box'>
                                                <div className='quote-heading'>Order ID:</div>
                                                <div className='value-heading'>{orderSummary?.id ? orderSummary?.id : "-"}</div>
                                            </div>

                                            <div className='quote-box'>
                                                <div className='quote-heading'>Quote Amount:</div>
                                                <div className='value-heading'>
                                                    {/* {orderSummary?.attributes?.quote_amount?orderSummary?.attributes?.quote_amount:"-"} */}
                                                    {orderSummary?.attributes?.quotes_provided !== undefined &&
                                                        orderSummary?.attributes?.quotes_provided !== '' ? (
                                                        <>
                                                            {orderSummary?.attributes?.quotes_provided
                                                                .split(/([0-9.,]+)/)
                                                                .map((part, index) =>
                                                                    index % 2 === 0 ? (
                                                                        part
                                                                    ) : (
                                                                        <span key={index}>
                                                                            {Number(part).toLocaleString('en-US', {
                                                                                minimumFractionDigits: 2,
                                                                                maximumFractionDigits: 2,
                                                                            })}
                                                                        </span>
                                                                    )
                                                                )}
                                                        </>
                                                    ) : (
                                                        0
                                                    )}

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="table-responsive alyya-table border-0">
                                        <table className="table table-borderless mb-4">
                                            <tbody>
                                                <tr>
                                                    <td className='px-0'>
                                                        <div className='d-flex align-items-center'>
                                                            <img src={orderSummary?.attributes?.order_equipments?.data?.attributes?.image} className="" alt="equipment" />
                                                            <div>
                                                                <strong className='d-block text-start'>{orderSummary?.attributes?.order_equipments?.data?.attributes?.product_name}</strong>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className='text-start  min-300 px-2'>
                                                        <div className='row m-0 p-0'>
                                                            <div className='col-6 px-0 pb-2'><div className='mb-2'>Type</div></div>
                                                            <div className='col-6 px-0 pb-2'><strong>{orderSummary?.attributes?.order_equipments?.data?.attributes.type}</strong></div>
                                                            <div className='col-6 px-0 pb-2'><div className='mb-2'>Capacity</div></div>
                                                            <div className='col-6 px-0 pb-2'><strong>{orderSummary?.attributes?.order_equipments?.data?.attributes?.capacity.value}</strong></div>
                                                            <div className='col-6 px-0 pb-2'><div className='mb-2'>Model</div></div>
                                                            <div className='col-6 px-0 pb-2'><strong>{orderSummary?.attributes?.order_equipments?.data?.attributes?.model}</strong></div>
                                                        </div>
                                                    </td>
                                                    <td className='text-start  min-300 px-2'>
                                                        <div className='row m-0 p-0'>
                                                            <div className='col-6 px-0 pb-2'><div className='mb-2'>Start Date</div></div>
                                                            <div className='col-6 px-0 pb-2' style={{ whiteSpace: 'nowrap' }}><strong>{Moment(orderSummary?.attributes?.start_date).format('DD-MM-YYYY')}</strong></div>
                                                            <div className='col-6 px-0 pb-2'><div className='mb-2'>End Date</div></div>
                                                            <div className='col-6 px-0 pb-2' style={{ whiteSpace: 'nowrap' }}><strong>{Moment(orderSummary?.attributes?.end_date).format('DD-MM-YYYY')}</strong></div>
                                                            <div className='col-6 px-0 pb-2'><div className='mb-2'>Quantity</div></div>
                                                            <div className='col-6 px-0 pb-2'><strong>{orderSummary?.attributes?.quantity}</strong></div>
                                                        </div>
                                                    </td>
                                                    <td className='text-start  min-300 px-2'>
                                                        <div className='row m-0 p-0'>
                                                            <div className='col-7 px-0 pb-2'><div className='mb-2'>Mobilisation provided?</div></div>
                                                            <div className='col-5 px-0 pb-2'><strong>{(orderSummary?.attributes?.mobilisation_provided === true) ? 'Yes' : 'No'}</strong></div>
                                                            <div className='col-7 px-0 pb-2'><div className='mb-2'>Zone/ Location</div></div>
                                                            <div className='col-5 px-0 pb-2'><strong>{orderSummary?.attributes?.order_equipments?.data?.attributes?.zone}</strong></div>
                                                            <div className='col-7 px-0 pb-2'><div className='mb-2'>Quote provided</div></div>
                                                            <div className='col-5 px-0 pb-2' style={{ whiteSpace: 'nowrap' }}>
                                                                <strong>
                                                                    <div className='d-inline-block'>SAR&nbsp;</div>
                                                                    {orderSummary?.attributes?.quotes_provided !== undefined &&
                                                                        orderSummary?.attributes?.quotes_provided !== '' ? (
                                                                        <>
                                                                            {orderSummary?.attributes?.quotes_provided
                                                                                .split(/([0-9.,]+)/)
                                                                                .map((part, index) =>
                                                                                    index % 2 === 0 ? (
                                                                                        part
                                                                                    ) : (
                                                                                        <span key={index}>
                                                                                            {Number(part).toLocaleString('en-US', {
                                                                                                minimumFractionDigits: 2,
                                                                                                maximumFractionDigits: 2,
                                                                                            })}
                                                                                        </span>
                                                                                    )
                                                                                )}
                                                                        </>
                                                                    ) : (
                                                                        0
                                                                    )}
                                                                </strong>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className='grand-total'>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <span className='gray-text'>Item Total</span><span className='price'><div className='d-inline-block'>SAR</div> {orderSummary?.attributes?.item_total !== undefined ? Number(orderSummary?.attributes?.item_total).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2, }).replace(/^(\D+)/, '') : 0}</span>
                                        </div>
                                        <div className='d-flex justify-content-between align-items-center mt-2'>
                                            <span className='link'>Taxes & Charges</span><span className='price'><div className='d-inline-block'>SAR</div> {orderSummary?.attributes?.taxes_and_charges !== undefined ? Number(orderSummary?.attributes?.taxes_and_charges).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2, }).replace(/^(\D+)/, '') : 0}</span>
                                        </div>
                                        <hr />
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <span className='grand-title'>Grand Total</span><span className='grand-price'><div className='d-inline-block'>SAR</div> {orderSummary?.attributes?.item_total !== undefined ? Number(orderSummary?.attributes?.item_total).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2, }).replace(/^(\D+)/, '') : 0}</span>
                                        </div>
                                    </div>

                                    <div className='title mb-2'>Order Details</div>

                                    <div className='order-detail'>Order Number</div><div className='order-detail-strong'>{orderSummary?.attributes?.order_number}</div>
                                    <div className='order-detail'>Date</div><div className='order-detail-strong'>{Moment(orderSummary?.attributes?.Date).format('DD-MM-YYYY')} at {Moment(orderSummary?.attributes?.Date).format('hh:mm A')}</div>
                                    <div className='order-detail'>Order status</div><div className='order-detail-strong' style={{ textTransform: 'capitalize' }}>{orderSummary?.attributes?.order_status}</div>
                                    <div className='order-detail'>Payment Status</div><div className='order-detail-strong' style={{ textTransform: 'capitalize' }}>{orderSummary?.attributes?.payment_status}</div>
                                    <div className='order-detail'>Phone Number</div><div className='order-detail-strong'>{orderSummary?.attributes?.phone_number}</div>
                                    <div className='order-detail'>Email ID</div><div className='order-detail-strong'>{orderSummary?.attributes?.email}</div>

                                    <hr className='my-4' />

                                    {/*<div className='orange-text'>Call Riyadh-Rentals at 909 090 009</div>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default AdminOrderSummary;
