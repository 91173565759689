import React from 'react';
import HireHeader from './HireHeader';
import HireFooter from './HireFooter';

function HowWorks() {
  return (
    <>
    <HireHeader/>
    <div className='filter-blank'></div>
    <div>HowWorks</div>
    <HireFooter/>
    </>
  )
};

export default HowWorks;